import React, { useContext } from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Feed } from './src/components/Feed';
import { NavigationBar } from './src/components/NavigationBar';
import { PersonalTree } from './src/components/PersonalTree';
import { OwnProfile } from './src/components/Profile';
import { TutorialSlides } from './src/components/TutorialSlides';
import { IsMaintainerContextProvider } from './src/functions/IsMaintainerContext';
import { LocalizedStringsContext, LocalizedStringsContextProvider } from './src/localization/localization.native';

import { Colors, Text, View } from 'react-native-ui-lib';
import FeedIcon from './assets/res/feed.svg';
import ProfileIcon from './assets/res/profile.svg';
import TreeIcon from './assets/res/tree.svg';
import { useIsNotMobile } from './src/MediaQueries';
import { signInAnonymousUser, useLoggedInState } from './src/firebaseWrapper/firebaseWrapper';
import { Splashscreen } from './src/components/Splashscreen';
import { linking } from './src/linking';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { Login, Registration } from './src/components/Authentication';

type TabParamList = {
  Feed: undefined;
  PersonalTree: undefined;
  Profile: undefined;
};

type DrawerParamList = {
  Feed: undefined;
  PersonalTree: undefined;
  Profile: undefined;
};

const Tab = createBottomTabNavigator<TabParamList>();
const Drawer = createDrawerNavigator<DrawerParamList>();

function AppContent(): JSX.Element {
  const [isLoggedIn, loading] = useLoggedInState();
  const isNotMobile = useIsNotMobile();
  const strings = useContext(LocalizedStringsContext);

  if (loading) {
    return <Splashscreen />;
  }

  if (!isLoggedIn) {
    signInAnonymousUser();
    return <Splashscreen />;
  }

  if (isNotMobile) {
    return (
      <>
        <Drawer.Navigator
          initialRouteName="Feed"
          backBehavior="initialRoute"
          // drawerContent={props => <NavigationDrawer {...props} />}
          screenOptions={{
            drawerType: 'permanent',
            drawerStyle: {
              backgroundColor: Colors.primaryBG,
              width: 250,
              borderRadius: 20,
              margin: 5,
              marginTop: 67,
            },
            drawerContentStyle: {
              width: 250,
              backgroundColor: Colors.secondaryBG,
              borderRadius: 20,
            },
            drawerLabelStyle: {
              fontWeight: 'bold',
              fontSize: 16,
            },
            drawerActiveTintColor: Colors.activeText,
            drawerInactiveTintColor: Colors.inactiveText,
            drawerInactiveBackgroundColor: 'transparent',
            drawerActiveBackgroundColor: 'transparent',
            headerShown: false,
          }}>
          <Drawer.Screen name="Feed" component={Feed}
            options={{
              drawerLabel: strings.public,
              title: strings.public,
              drawerIcon: ({ focused, color, size }) => (
                <FeedIcon width={size} height={size} fill={color} />
              ),
            }} />
          <Drawer.Screen name="PersonalTree" component={PersonalTree}
            options={{
              drawerLabel: strings.private,
              title: strings.private,
              drawerIcon: ({ focused, color, size }) => (
                <TreeIcon width={size} height={size} fill={color} />
              ),
            }} />
          <Drawer.Screen name="Profile" component={OwnProfile}
            options={{
              drawerLabel: strings.profile,
              title: strings.profile,
              drawerIcon: ({ focused, color, size }) => (
                <ProfileIcon width={size} height={size} fill={color} />
              ),
            }} />
          <Drawer.Screen name="register" component={Registration} options={{ drawerItemStyle: { display: 'none' } }} />
          <Drawer.Screen name="login" component={Login} options={{ drawerItemStyle: { display: 'none' } }} />
        </Drawer.Navigator>
        <TutorialSlides />
      </>
    );
  }

  return (
    <>
      <Tab.Navigator
        initialRouteName="Feed"
        backBehavior="initialRoute"
        tabBar={props => <NavigationBar {...props} />}
        screenOptions={{
          headerShown: false,
        }}>
        <Tab.Screen name="Feed" component={Feed} />
        <Tab.Screen name="PersonalTree" component={PersonalTree} />
        <Tab.Screen name="Profile" component={OwnProfile} />
        <Tab.Screen name="register" component={Registration} />
        <Tab.Screen name="login" component={Login} />
      </Tab.Navigator>
      <TutorialSlides />
    </>
  );
}

export default function App(): JSX.Element {
  return (
    <NavigationContainer
      linking={linking}
      fallback={<Splashscreen />}
    >
      <View flex backgroundColor={Colors.primaryBG}>
        <AppContext>
          <AppContent />
        </AppContext>
      </View>
    </NavigationContainer>
  );
}

function AppContext({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <SafeAreaProvider>
      <IsMaintainerContextProvider>
        <LocalizedStringsContextProvider>
          {children}
        </LocalizedStringsContextProvider>
      </IsMaintainerContextProvider>
    </SafeAreaProvider>
  );
}
