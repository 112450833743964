import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';

import React from 'react';

import {Text, View} from 'react-native-ui-lib';

import {CreateDialog, EditDialog} from './Dialogs';
import {Header} from './Header';
import {StatementList} from './StatementList';
import {StatementThread} from './StatementThread';
import {ContainerType, FirestoreConstants, navigationProps} from '../typings/types';
import { useLoggedInState } from '../firebaseWrapper/firebaseWrapper';
import { applyOnPlatform, convertInfoToRouteParams, convertPathToInfo } from '../helperFunctions';
import { PersonalStatementView, StatementView } from './StatementView';

type PersonalTreeStackParams = {
  PersonalList: undefined;
  PersonalStatementView: {path: FirestoreConstants};
  CreateDialog: undefined;
  EditDialog: {path: FirestoreConstants};
};

export function PersonalTree(): JSX.Element {
  const Stack = createStackNavigator<PersonalTreeStackParams>();
  const isLoggedIn = useLoggedInState();

  if (!isLoggedIn) {
    return (
      <View
        bg-primaryBG
        style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text style={{fontSize: 25, color: 'darkgrey'}}>
          Please register or log in to use the personal tree.
        </Text>
      </View>
    );
  }

  return (
    <Stack.Navigator
      initialRouteName="PersonalList"
      screenOptions={navigationProps => Header(navigationProps)}>
      <Stack.Screen
        name="PersonalList"
        component={PersonalList}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="PersonalStatementView"
        component={PersonalStatementView}
        initialParams={convertPathToInfo(FirestoreConstants.ROOT_PATH)}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="StatementThread"
        component={StatementThread}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="CreateDialog"
        component={CreateDialog}
        options={{
          presentation: 'transparentModal',
          headerLeft: () => undefined,
          ...TransitionPresets.ModalFadeTransition,
        }}
      />
      <Stack.Screen
        name="EditDialog"
        component={EditDialog}
        options={{
          presentation: 'transparentModal',
          headerLeft: () => undefined,
          ...TransitionPresets.ModalFadeTransition,
        }}
      />
    </Stack.Navigator>
  );
}

function PersonalList({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const info = convertPathToInfo(FirestoreConstants.ROOT_PATH);
  info.type = ContainerType.CATEGORY;
  route = {...route, params: convertInfoToRouteParams(info)};

  return (
    <StatementView navigation={navigation} route={route} />
  );
}