import React from 'react';
import { Button, Colors, Text, View } from 'react-native-ui-lib';
import { getFeedElementsQuery, proposeArgument, useLazyElementsLoader } from '../firebaseWrapper/firebaseWrapper';
import { StanceType, navigationProps } from '../typings/types';
import { List, TopicList, TopicListPublic } from './StatementList';
import { Pressable, TextInput } from 'react-native';
import { styles } from '../styles';
import { strings } from '../localization/localization.web';
import LinearGradient from 'react-native-linear-gradient';
import { useIsMobile } from '../MediaQueries';

export function FeedList({ navigation, route }: navigationProps): JSX.Element {

  const isMobile = useIsMobile();

  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG
      }}
    >
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: 20,
          marginHorizontal: isMobile ? 0 : 5,
          marginVertical: 5,
          overflow: 'hidden',
        }}
      >
        <View style={{ marginLeft: isMobile ? 10 : 25, marginTop: isMobile ? 0 : 20 }}>
          <TopicListPublic />
        </View>
        <LazyList
          navigation={navigation}
          route={route}
          query={getFeedElementsQuery()}
        />
      </View>
    </View>
  );
}

type LazyListProps = {
  navigation: any;
  route: any;
  query: any;
};

export function LazyList({
  navigation,
  route,
  query,
}: LazyListProps): JSX.Element {
  const parentInfo = route.params?.parentInfo || '';
  const {
    infos,
    loading,
    error,
    refresh,
    loadMoreElements: loadMoreElements,
  } = useLazyElementsLoader(query, parentInfo);

  if (error) {
    return (
      <View flex center>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  const loadMoreEntriesWrapper = () => {
    loadMoreElements(10);
  };

  return (
    <>
      {infos && infos.length > 0 && (
        <List
          infoList={infos}
          parentInfo={parentInfo}
          navigation={navigation}
          loading={loading}
          onRefresh={refresh}
          onEndReached={loadMoreEntriesWrapper}
        />
      )}
    </>
  );
}
