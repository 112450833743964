import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import React from 'react';

import { Text, View } from 'react-native-ui-lib';
import { useLoggedInState } from '../firebaseWrapper/firebaseWrapper';

import { applyOnPlatform } from '../helperFunctions';
import { dbPath, FirestoreConstants } from '../typings/types';
import { DeepLinkingEntryScreen } from './DeepLinking';
import { CreateDialog } from './Dialogs';
import { FeedList } from './FeedList';
import { Header } from './Header';
import { ProfileView } from './Profile';
import { PublicStatementView } from './StatementView';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Login, Registration } from './Authentication';

type FeedStackParamList = {
  FeedList: undefined;
  StatementView: { path: dbPath };
  CreateDialog: undefined;
  DeepLinkingEntryScreen: undefined;
  DeepLinkingEntryScreenPersonal: undefined;
  DeepLinkingEntryScreenPublic: undefined;
  DeepLinkingEntryScreenGroup: undefined;
  DeepLinkingEntryScreenGroupInvite: undefined;
  DeepLinkingEntryScreenUser: undefined;
};

export function Feed(): JSX.Element {
  const Stack = createStackNavigator<FeedStackParamList>();

  return (
    <Stack.Navigator
      initialRouteName="FeedList"
      screenOptions={navigationProps => Header(navigationProps)}
    >
      <Stack.Screen name="FeedList" component={FeedList} />
      <Stack.Screen
        name="PublicStatementView"
        component={PublicStatementView}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="CreateDialog"
        component={CreateDialog}
        options={{
          presentation: 'transparentModal',
          headerLeft: () => undefined,
          ...TransitionPresets.ModalFadeTransition,
        }}
      />
      <Stack.Screen
        name="profile"
        component={ProfileView}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenPublic"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenPersonal"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenGroup"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenGroupInvite"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenUser"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
}
