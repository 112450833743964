import { getPathFromState } from "@react-navigation/native";

export const linking = {
  prefixes: [
    'https://www.app.lucid-mind.eu',
    'http://www.app.lucid-mind.eu',
    'https://app.lucid-mind.eu',
    'http://app.lucid-mind.eu',
  ],
  // TODO: Remove this workaround once a better solution is found.
  // Currently using separate screens for public and personal tree.
  // Therefore, loc is not a parameter of the route but a literal in the path.
  // As result, sending loc as a parameter in the url appends it with ?loc=...
  // This workaround removes the loc parameter from the path.
  getPathFromState(state: any, config: any) {
    let path = getPathFromState(state, config);
    if(path.endsWith("?loc=public")) {
      path = path.replace("?loc=public", "");
    } else if(path.endsWith("?loc=personal")) {
      path = path.replace("?loc=personal", "");
    }
    return path;
  },
  config: {
    screens: {
      Feed: {
        path: 'public/',
        initialRouteName: 'FeedList',
        screens: {
          FeedList: {
            path: '/',
          },
          PublicStatementView: {
            path: ':type/:id',
          },
          // DeepLinkingEntryScreenPublic: {
          //   path: 'public/:elementType/:elementId',
          //   exact: true,
          // },
          // DeepLinkingEntryScreenPersonal: {
          //   path: 'user/:userId/:elementType/:elementId',
          //   exact: true,
          // },
          // DeepLinkingEntryScreenUser: {
          //   path: 'user/:userId',
          //   exact: true,
          // },
          // DeepLinkingEntryScreenGroup: {
          //   path: 'group/:groupId/:elementType/:elementId',
          //   exact: true,
          // },
          // DeepLinkingEntryScreenGroupInvite: {
          //   path: 'group/:groupId/invite/:inviteToken',
          //   exact: true,
          // },
        },
      },
      PersonalTree: {
        path: 'personal/',
        initialRouteName: 'PersonalList',
        screens: {
          PersonalList: {
            path: '/',
          },
          PersonalStatementView: {
            path: ':type/:id',
          },
        },
      },
      Profile: {
        path: 'profile/',
        initialRouteName: 'ProfileContent',
        screens: {
          ProfileContent: {
            path: '/',
          },
        },
      },
    },
  },
};