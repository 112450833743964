import AsyncStorage from '@react-native-async-storage/async-storage';
import { ActivityIndicator } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import AppIntroSlider from 'react-native-app-intro-slider';
import { GestureHandlerRootView, ScrollView } from 'react-native-gesture-handler';
import { Colors, Image, Text, TouchableOpacity, View } from 'react-native-ui-lib';
import ArrowBackIcon from '../../assets/res/arrow_back.svg';
import ArrowForwardIcon from '../../assets/res/arrow_forward.svg';
import CheckIcon from '../../assets/res/check.svg';
import CloseIcon from '../../assets/res/close.svg';
import { getNumOfTutorialSlides, getTutorialSlideData, getTutorialSlideImage } from '../firebaseWrapper/firebaseWrapper';
import { strings } from '../localization/localization.web';
import { useIsMobile } from '../MediaQueries';

// TODO: must be handled on the server side!
const markIntroductorySlidesAsSeen = async () => {
  try {
    await AsyncStorage.setItem('@seen_intro', 'true');
  } catch (e) {
    // handle error
    console.error(e);
  }
};

export const checkIfIntroductorySlidesHaveBeenSeen = async () => {
  try {
    const value = await AsyncStorage.getItem('@seen_intro');
    if (value === 'true') {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    // handle error
  }
};

// TODO: Needs a lot of refactoring and possibly new algorithm for loading slides.
export function TutorialSlides(): JSX.Element {
  const [showSlides, setShowSlides] = useState(false);
  const [slidesData, setSlidesData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [dimensions, setDimensions] = useState(Dimensions.get("window"));
  const isMobile = useIsMobile();

  const pathToSlides = 'publicData/tutorials/introductorySlides';

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  });

  useEffect(() => {
    checkIfIntroductorySlidesHaveBeenSeen().then(res => {
      setShowSlides(!res);
      if (!res) {
        getNumOfTutorialSlides()
          .then(numOfSlides => {
            const promises = [];
            for (let i = 0; i < numOfSlides; i++) {
              const slidePath = `${pathToSlides}/${i}`;
              const slideData: {[key: string]: string} = {};
              const promise = getTutorialSlideData(slidePath)
                .then(res => {
                  slideData['text'] = res.text;
                  return getTutorialSlideImage(res.imageURL)
                    .then(url => {
                      slideData['imageURL'] = url;
                      return slideData;
                    })
                    .catch(err => {
                      console.error(err);
                      return err;
                    });
                })
                .catch(err => {
                  console.error(err);
                  return err;
                });
              promises.push(promise);
            }
            Promise.all(promises)
              .then(res => {
                setSlidesData(res);
                setLoading(false);
              })
              .catch(err => {
                console.error(err);
                setLoading(false);
              });
          })
          .catch(err => {
            console.error(err);
            setLoading(false);
          });
      }
    });
  }, []);

  if (!showSlides) {
    return <></>;
  }

  const dialogMaxHeight = dimensions.height - 120;
  let imgSize =  dialogMaxHeight / 2;
  if(imgSize > dimensions.width - 40) {
    imgSize = dimensions.width - 40;
  }

  if (!showSlides) {
    return <></>;
  }

  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      }}>
      {loading ? (
        <View centerH centerV flex>
          <ActivityIndicator
            color={Colors.iconAccent}
            style={{backgroundColor: 'transparent', borderRadius: 20, padding: 20}}
          />
        </View>
      ) : (
        <AppIntroSlider
          renderItem={({item}) => {
            return (
              <View centerH centerV flex style={{justifyContent: 'center', alignContent: 'center'}}>
                <View
                  style={{
                    flex: 1,
                    backgroundColor: Colors.primaryBG,
                    minHeight: isMobile ? dimensions.height : dimensions.height*0.95,
                    maxWidth: isMobile ? dimensions.width : imgSize + 50,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    padding: 20,
                    paddingTop: isMobile ? 60 : 0,
                    paddingBottom: isMobile ? 0 : 30,
                    marginTop: isMobile ? 0 : dimensions.height * 0.025,
                    marginBottom: isMobile ? 0 : dimensions.height * 0.025,
                    borderRadius: isMobile ? 0 : 20
                  }}
                >
                  <Image
                    source={{uri: item['imageURL']}}
                    style={{width: imgSize, height: imgSize, borderRadius: 20}}
                    resizeMode="contain"
                  />
                  <GestureHandlerRootView style={{flex: 1}}>
                    <ScrollView style={{flexShrink: 1}}>
                      <Text
                        marginH-25
                        marginV-15
                        marginB-0
                        style={{textAlign: 'justify', fontSize: 16, color: Colors.textPrimary}}>
                        {(item['text'] as string).replace(/\\n/g, '\n')}
                      </Text>
                    </ScrollView>
                  </GestureHandlerRootView>
                </View>
              </View>
            );
          }}
          renderNextButton={() => (
            <ArrowForwardIcon style={{margin: 10, marginRight: isMobile? 0 : dimensions.width / 2 - imgSize * 0.6}} fill={Colors.iconPrimary} />
          )}
          renderDoneButton={() => (
            <CheckIcon style={{margin: 10, marginRight: isMobile? 0 : dimensions.width / 2 - imgSize * 0.6}} fill={Colors.iconAccent} />
          )}
          renderPrevButton={() => (
            <ArrowBackIcon style={{margin: 10, marginLeft: isMobile? 0 : dimensions.width / 2 - imgSize * 0.6}} fill={Colors.iconPrimary} />
          )}
          dotStyle={{backgroundColor: Colors.iconSecondary}}
          activeDotStyle={{backgroundColor: Colors.iconAccent}}
          showPrevButton={true}
          data={slidesData}
          onDone={() => {
            setShowSlides(false);
            markIntroductorySlidesAsSeen();
          }}
        />
      )}
      <TouchableOpacity
        onPress={() => {
          setShowSlides(false);
          markIntroductorySlidesAsSeen();
        }}
        style={{position: 'absolute', top: 0, right: isMobile? 0 : dimensions.width / 2 - imgSize * 0.6, margin: 15}}>
        <CloseIcon fill={Colors.iconPrimary} style={{margin: 10}} />
      </TouchableOpacity>
    </View>
  );
}
