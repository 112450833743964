import { FC, useCallback } from "react";
import { Alert, Linking, Pressable } from "react-native";
import { Colors, Text, View } from "react-native-ui-lib";
import LogoIcon from '../../assets/res/LogoLM.svg';
import { strings } from "../localization/localization.web";
import { FlatList } from "react-native-gesture-handler";

export type FooterProps = {
    navigation: any
}

/**
 *
 * @returns Footer component.
 */
export const Footer: FC<FooterProps> = ({ navigation }) => {

    const links = [
        {
            label: strings.impressum,
            url: "https://www.lucid-mind.eu/impressum",
        },
        {
            label: strings.privacy_policy,
            url: "https://www.lucid-mind.eu/privacy",
        },
        // {
        //     label: strings.AGB,
        //     onPress: () => { },
        // },
        // {
        //     label: strings.contact,
        //     onPress: () => { },
        // },
        // {
        //     label: strings.FAQ,
        //     onPress: () => { },
        // },
        // {
        //     label: strings.about_us,
        //     onPress: () => { },
        // }
    ];



    return (
        <View row paddingH-20 paddingB-60 paddingT-40 style={{ justifyContent: 'space-evenly' }}>
            <View flex>
                <LogoIcon height={0.9 * 30} width={0.9 * 50} />
                <Text marginT-10 textSecondary>
                    © 2024 Copyright
                </Text>
            </View>
            <View flex>
                <FlatList
                    data={links}
                    renderItem={({ item }) => (
                        <LinkButton label={item.label} url={item.url} />
                    )}
                />
            </View>
        </View>
    )

}

type LinkButtonProps = {
    label: string,
    url: string,
};

const LinkButton: FC<LinkButtonProps> = ({ label, url }) => {

    const handlePress = useCallback(async () => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
            await Linking.openURL(url);
        } else {
            Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }, [url]);

    return (
        <Pressable onPress={handlePress}>
            {({ pressed }) => (
                <Text style={{ color: pressed ? Colors.textPrimary : Colors.textSecondary }}>
                    {label}
                </Text>
            )}
        </Pressable>
    );

}
