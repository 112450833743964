import { FC, useContext, useEffect, useState } from 'react';
import { TextInput } from 'react-native-gesture-handler';
import {
  ActionSheet,
  Colors,
  Dialog,
  KeyboardAwareScrollView,
  Text,
  TextArea,
  TextField,
  TouchableOpacity,
  View,
} from 'react-native-ui-lib';
import {
  CategoryData,
  SourceData,
  StatementData,
  StatementGroupData,
  useContainerListener,
  useStatementListener
} from '../dataModel';
import {
  containerTypeToString, convertInfoToPath, convertRouteParamsToInfo
} from '../helperFunctions';
import { LocalizedStringsContext } from '../localization/localization.native';
import ArrowDropDownIcon from '../../assets/res/arrow_drop_down.svg';
import CreateIcon from '../../assets/res/check.svg';
import CancelIcon from '../../assets/res/close.svg';
import FlipIcon from '../../assets/res/flip.svg';
import ExclamationMarkIcon from '../../assets/res/exclamationMark.svg';
import CrossIcon from '../../assets/res/cross.svg';
import CheckmarkIcon from '../../assets/res/checkmark.svg';

import React from 'react';

import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Alert, FlatList, KeyboardAvoidingView, Modal, NativeSyntheticEvent, Platform, Pressable, TextInputChangeEventData } from 'react-native';
import { makeContainerInDB, proposeArgument, publishSubtree } from '../firebaseWrapper/firebaseWrapper';
import { ConflictSeverity, ConflictType, ContainerType, ElementPath, FirestoreConstants, StanceType, navigationProps } from '../typings/types';
import { gradientPink, styles } from '../styles';
import { strings } from '../localization/localization.web';
import { useIsDesktop, useIsMobile, useIsTablet } from '../MediaQueries';
import { alert } from './Alert';
import { MaterialIcons } from '@expo/vector-icons';
import { ConditionsCard, Container, Statement } from './Containers';
import LinearGradient from 'react-native-linear-gradient';
import { ArgumentThreadHeader } from './StatementThread';
import { ButtonPrimary } from './Buttons';

export function CreateDialog({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const [typeId, setTypeId] = useState(
    route.params.typeId ? route.params.typeId : ContainerType.STATEMENT,
  );
  const parentLoc = route.params.loc;
  const parentType = route.params.type;
  const parentId = route.params.id;
  const parentInfo = convertRouteParamsToInfo({ loc: parentLoc, type: parentType, id: parentId });

  const [content, setContent] = useState('');
  const [url, setUrl] = useState('');
  const [reference, setReference] = useState('');
  const [name, setName] = useState('');
  const [showTypeMenu, setShowTypeMenu] = useState(false);
  const [showParent, setShowParent] = useState(typeId === ContainerType.STATEMENT_GROUP);
  const [stance, setStance] = useState(StanceType.SUPPORT);
  const [conditions, setConditions] = useState([{ content: "", id: 0, height: 0 }]);
  const { statement, loading, error } = useStatementListener(parentInfo);
  const [proposedArgument, setProposedArgument] = React.useState<{ premises: string[] }>();
  const [generatingArgument, setGeneratingArgument] = React.useState(false);

  const handleAddCondition = () => {
    setConditions((previousConditions) => [
      ...previousConditions,
      { content: '', id: previousConditions.reduce((max, obj) => Math.max(max, obj.id), -Infinity) + 1, height: 0 }
    ]);
  }

  const handleDeleteCondition = (desiredConditionIndex: number) => {
    console.log(desiredConditionIndex)
    if (conditions.length <= 1) {
      // onClose();
      setConditions(conditions.filter((conditions) => desiredConditionIndex !== conditions.id));
    } else {
      setConditions(
        conditions.filter((conditions) => desiredConditionIndex !== conditions.id)
      );
    }
  }

  const handleChangeCondition = (value: string, desiredConditionIndex: number) => {
    setConditions(
      conditions.map((condition) =>
        condition.id === desiredConditionIndex
          ? { content: value, id: condition.id, height: condition.height }
          : condition
      )
    );
  };

  // TODO Use potentially when text area height is set dynamically
  // const handleUpdateConditionHeight = (height: number, desiredConditionIndex: number) => {
  //   console.log("height", height)
  //   setConditions(
  //     conditions.map((condition) =>
  //       condition.id === desiredConditionIndex
  //         ? { content: condition.content, id: condition.id, height: height }
  //         : condition
  //     )
  //   );
  // };

  const handleProposeArgument = (statement: string, stance: StanceType) => {
    setConditions(
      conditions.filter((conditions) => false)
    );
    setGeneratingArgument(true);
    proposeArgument(statement, stance)
      .then((result) => {
        setProposedArgument(result)
        const newConditions = result.premises.map((premise: string, index: number) => { return { content: premise, id: index, height: 38 } });
        setConditions((previousConditions) => newConditions);
        setGeneratingArgument(false);
      })
      .catch((error) => {
        alert(strings.error, strings.error_generate_argument);
        setGeneratingArgument(false)
      });
  };

  const stanceUI = (
    <View center>
      <TouchableOpacity
        row
        centerV
        padding-5
        onPress={() =>
          setStance(
            stance === StanceType.SUPPORT
              ? StanceType.OPPOSE
              : StanceType.SUPPORT,
          )
        }
        style={{
          borderColor: stance === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG,
          borderWidth: 1,
          borderRadius: 10,
          alignItems: 'center'
        }}
      >
        <Text
          marginR-3
          bold
          style={{ color: stance === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG }}>
          {stance === StanceType.SUPPORT ? strings.support : strings.oppose}
        </Text>
        <FlipIcon
          height={20} width={20}
          color={stance === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG}
          fill={stance === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG}
        ></FlipIcon>
      </TouchableOpacity>
    </View>
  );

  const contentUI = (
    <View padding-10 paddingB-0>
      <KeyboardAwareScrollView>
        <TextField
          paddingT-10
          placeholder={typeId === ContainerType.STATEMENT ? strings.add_statement_content_hint : strings.add_source_content_hint}
          placeholderTextColor={Colors.textSecondary}
          content
          color={Colors.textPrimary}
          multiline
          textAlign="left"
          numberOfLines={typeId === ContainerType.STATEMENT ? 4 : 2}
          onChangeText={(text: React.SetStateAction<string>) => setContent(text)}
          style={{ textAlignVertical: 'top' }}
        />
      </KeyboardAwareScrollView>
    </View>
  );

  const nameUI = (
    <TextField
      marginT-10
      paddingT-10
      placeholder={strings.add_topic_name_hint}
      placeholderTextColor={Colors.textSecondary}
      color={Colors.textPrimary}
      onChangeText={text => setName(text)}
      style={{ textAlignVertical: 'top', textAlign: 'center' }}
    />
  );

  const urlAndReferenceUI = (
    <View padding-10>
      <TextInput
        placeholder={strings.add_source_url_hint}
        placeholderTextColor={Colors.textSecondary}
        keyboardType="url"
        onChangeText={text => setUrl(text)}
        style={{
          color: Colors.textSeconary,
          flexShrink: 1,
        }}
      />
      {/* <View row centerV>
        <Text marginL-5 flexS>
          {'('}
        </Text>
        <TextInput
          placeholder={'.....'}
          placeholderTextColor={Colors.textSecondary}
          onChangeText={text => setReference(text)}
          style={{
            color: Colors.textPrimary,
            flexShrink: 1,
            height: 40,
          }}
        />
        <Text flexG>{')'}</Text>
      </View> */}
    </View>
  );

  const conditionsUi = (
    <View
      style={{
        backgroundColor: Colors.secondaryBG,
        elevation: 3,
        marginLeft: 13
      }}
    >
      <ConditionsCard stance={stance} drag={undefined} arrowBorder={true}>
        <View
          width={'100%'}
          style={{
            backgroundColor: Colors.secondaryBG,
            borderRadius: 15,
            borderTopLeftRadius: 0,
            width: '100%',
            padding: 10
          }}>
          <FlatList
            data={conditions}
            contentContainerStyle={{ paddingBottom: 10 }}
            renderItem={((condition) =>
              <View bg-quaternaryBG br60 padding-10 width={'100%'} marginB-10>
                <KeyboardAwareScrollView bg-quaternaryBG >
                  <TextArea
                    placeholder={strings.add_condition_content_hint}
                    placeholderTextColor={Colors.textSecondary}
                    value={condition.item.content}
                    color={Colors.textPrimary}
                    multiline
                    rows={4}
                    onChangeText={(text: React.SetStateAction<string>) =>
                      handleChangeCondition(text.toString(), condition.item.id)
                    }
                  />
                  <View style={{ justifyContent: 'flex-end' }}>
                    <Pressable onPress={() => handleDeleteCondition(condition.item.id)}>
                      <MaterialIcons name="delete-outline" size={24} color={Colors.iconPrimary} />
                    </Pressable>
                  </View>
                </KeyboardAwareScrollView>
              </View>
            )}
          />
          <Pressable
            onPress={handleAddCondition}
            style={[styles.backgroundNone, styles.borderTertiary, { padding: 10, justifyContent: 'center' }]}
          >
            <Text iconSecondary center>
              {strings.add_condition}
            </Text>
          </Pressable>
          <View marginT-5 />
          <ButtonPrimary label={generatingArgument ? strings.generating_argument : strings.propose_arguments} onPress={() => handleProposeArgument(statement.getContent(), stance)} />
        </View>
      </ConditionsCard>
    </View>
  );

  const handleCreatePress = () => {
    makeContainerInDB(convertInfoToPath(parentInfo), typeId, content, stance, url, reference, name, conditions.map(condition => condition.content));
    navigation.goBack();
  };

  if (loading) {
    return <></>;
  }

  return (
    <Dialog visible={true} onDismiss={() => navigation.goBack()} renderPannableHeader={(props: any) => <View></View>}>

      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <View padding-15 style={{ borderRadius: 18, backgroundColor: Colors.secondaryBG }}>
          <View row style={{ justifyContent: 'space-between' }}>
            <Text h3 textPrimary>
              {`${containerTypeToString(typeId, strings)} ${strings.add.toLocaleLowerCase()}`}
            </Text>
            {[
              ContainerType.STATEMENT,
              ContainerType.SOURCE,
              ContainerType.STATEMENT_GROUP,
            ].includes(typeId) &&
              ![ContainerType.CATEGORY, ContainerType.STATEMENT_GROUP].includes(
                statement.getType(),
              ) &&
              statement.getPath() !== null && statement.getPath() !== FirestoreConstants.ROOT_PATH
              ? stanceUI
              : undefined}
          </View>
          <View
            style={{
              backgroundColor: typeId === ContainerType.STATEMENT_GROUP ? 'transparent' : 'transparent',
              borderRadius: 18,
            }}
          >
            {showParent ?
              <View flex style={{ height: '100%', padding: 10, paddingBottom: 0 }}>
                <Container info={parentInfo} navigation={navigation} parentInfo={null} showHeader={false} showOptions={false} showStanceIndicator={false} />
              </View>
              : null
            }
            <View
              style={{
                backgroundColor: typeId === ContainerType.STATEMENT_GROUP ? 'transparent' : Colors.quaternaryBG,
                borderRadius: 18,
                padding: 10,
                paddingTop: 0,
                marginTop: typeId === ContainerType.STATEMENT_GROUP ? 0 : 10,
                // flex: ContainerType.STATEMENT_GROUP === typeId ? 0 : 1,
              }}
            >
              {[ContainerType.STATEMENT, ContainerType.SOURCE].includes(typeId) &&
                contentUI}
              {typeId === ContainerType.STATEMENT_GROUP ?
                <View style={{ marginLeft: -2 }}>
                  <ArgumentThreadHeader stance={stance} />
                </View> : null}
              {typeId === ContainerType.STATEMENT_GROUP ? conditionsUi : null}
              {ContainerType.CATEGORY === typeId && nameUI}
              {ContainerType.SOURCE === typeId && urlAndReferenceUI}
              <View flex row style={{ justifyContent: 'flex-end', width: '100%' }}>
                <TouchableOpacity
                  padding-10
                  onPress={() => {
                    navigation.goBack();
                  }}>
                  <CancelIcon width={24} height={24} fill={Colors.iconPrimary} />
                </TouchableOpacity>
                <TouchableOpacity
                  padding-10
                  onPress={() => {
                    handleCreatePress()
                  }}>
                  <CreateIcon width={24} height={24} fill={Colors.iconAccent} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <TypesMenu
            visible={showTypeMenu}
            setVisible={setShowTypeMenu}
            currentType={typeId}
            possibleTypes={route.params?.possibleTypes ?? []}
            setNewType={setTypeId}
          />
        </View>
      </KeyboardAvoidingView>
    </Dialog>
  );
}

export function EditDialog({ navigation, route }: navigationProps): JSX.Element {
  const loc = route.params.loc;
  const type = route.params.type;
  const id = route.params.id;

  const strings = useContext(LocalizedStringsContext);
  const { container, loading, error } = useContainerListener(convertRouteParamsToInfo({ loc, type, id }));
  const parentAvailable = route.params && route.params.parentLoc && route.params.parentType && route.params.parentId;
  const parent = useContainerListener(
    route.params && route.params.parentLoc && route.params.parentType && route.params.parentId ?
      convertRouteParamsToInfo({ loc: route.params.parentLoc, type: route.params.parentType, id: route.params.parentId })
      : undefined,
  );
  const parentContainer = parent.container;
  const parentLoading = parent.loading;
  const parentError = parent.error;
  // As of now only used to initiate ui update of the stance.
  // No clue how to make the ui update when the stance in the parent container changes as it is directy modifying a state variable.
  // However, neither do the other properties which is weird.
  const [stance, setStance] = useState<StanceType>(StanceType.NEUTRAL);
  const [showParent, setShowParent] = useState(container.getType() === ContainerType.STATEMENT_GROUP);
  const [newConditions, setNewConditions] = useState([]);
  const [removedConditions, setRemovedConditions] = useState([]);

  useEffect(() => {
    setShowParent(container.getType() === ContainerType.STATEMENT_GROUP);
  }, [container]);

  const handleAddCondition = () => {
    setNewConditions((previousConditions) => [
      ...previousConditions,
      { content: '', id: previousConditions.reduce((max, obj) => Math.max(max, obj.id), 0) + 1 }
    ]);
  }

  const handleDeleteCondition = (desiredConditionInfo: ElementPath) => {
    container.getChildData();
    const conditionDataWithoutDeleted = container.getChildData().filter((conditionData) => conditionData[convertInfoToPath(desiredConditionInfo)] === undefined);
    container.setChildData(conditionDataWithoutDeleted);
    setRemovedConditions([...removedConditions, desiredConditionInfo]);
  }

  const handleDeleteNewCondition = (desiredConditionIndex: Number) => {
    setNewConditions(
      newConditions.filter((condition) => desiredConditionIndex !== condition.id)
    );
  }

  const handleChangeCondition = (value: string, desiredConditionIndex: number) => {
    setNewConditions(
      newConditions.map((condition) =>
        condition.id === desiredConditionIndex
          ? { content: value, id: condition.id }
          : condition
      )
    );
  };


  if (loading || (parentAvailable && parentLoading)) {
    return <></>;
  } else if (error || (parentAvailable && parentError)) {
    return <></>;
  }

  container.keepUpdatesLocal();
  parentContainer.keepUpdatesLocal();

  let innerUI: JSX.Element | undefined = undefined;
  if (container.getType() === ContainerType.STATEMENT) {
    innerUI = <EditStatementDialog statement={container as StatementData} />;
  } else if (container.getType() === ContainerType.SOURCE) {
    innerUI = <EditSourceDialog source={container as SourceData} />;
  } else if (container.getType() === ContainerType.CATEGORY) {
    innerUI = <EditCategoryDialog category={container as CategoryData} />;
  } else if (container.getType() === ContainerType.STATEMENT_GROUP) {
    innerUI = <EditArgumentDialog argument={container as StatementGroupData} />;
  }

  let stanceUI = undefined;
  if (
    parentAvailable &&
    [
      ContainerType.SOURCE,
      ContainerType.STATEMENT_GROUP,
    ].includes(container.getType()) &&
    ![
      ContainerType.STATEMENT_GROUP,
      undefined,
      null,
    ].includes(parentContainer.getType())
  ) {
    stanceUI = (
      <View center>
        <TouchableOpacity
          row
          centerV
          padding-5
          onPress={() => {
            parentContainer.setChildStance(
              container.getInfo(),
              parentContainer.getChildStance(container.getPath()) ===
                StanceType.SUPPORT
                ? StanceType.OPPOSE
                : StanceType.SUPPORT,
            );
            setStance(parentContainer.getChildStance(container.getPath()));
          }}
          style={{
            borderColor: parentContainer.getChildStance(container.getPath()) === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG,
            borderWidth: 1,
            borderRadius: 10,
            alignItems: 'center'
          }}
        >
          <Text
            marginR-3
            bold
            style={{ color: parentContainer.getChildStance(container.getPath()) === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG }}>
            {parentContainer.getChildStance(container.getPath()) === StanceType.SUPPORT ? strings.support : strings.oppose}
          </Text>
          <FlipIcon
            height={20} width={20}
            color={parentContainer.getChildStance(container.getPath()) === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG}
            fill={parentContainer.getChildStance(container.getPath()) === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG}
          ></FlipIcon>
        </TouchableOpacity>
      </View>
    );
  }

  const conditionsUi = !parentAvailable ? null : (
    <View
      style={{
        backgroundColor: Colors.secondaryBG,
        elevation: 3,
        marginLeft: 13
      }}
    >
      <ConditionsCard stance={parentContainer.getChildStance(container.getPath())} drag={undefined} arrowBorder={true}>
        <View
          width={'100%'}
          style={{
            backgroundColor: Colors.secondaryBG,
            borderRadius: 15,
            borderTopLeftRadius: 0,
            flexGrow: 1,
            width: '100%',
            padding: 10
          }}>
          <FlatList
            data={container.getChildInfos()}
            renderItem={((conditionInfo) => removedConditions.includes(conditionInfo.item) ? null :
              <View bg-quaternaryBG br60 padding-10 width={'100%'} marginB-10>
                <KeyboardAwareScrollView bg-quaternaryBG >
                  <Statement info={conditionInfo.item} parentInfo={undefined} navigation={undefined} showHeader={false} />
                  <View flex style={{ justifyContent: 'flex-end' }}>
                    <Pressable onPress={() => handleDeleteCondition(conditionInfo.item)}>
                      <MaterialIcons name="delete-outline" size={24} color={Colors.iconPrimary} />
                    </Pressable>
                  </View>
                </KeyboardAwareScrollView>
              </View>
            )}
          />
          <FlatList
            data={newConditions}
            renderItem={((condition) =>
              <View bg-quaternaryBG br60 padding-10 width={'100%'} marginB-10>
                <KeyboardAwareScrollView bg-quaternaryBG >
                  <TextArea
                    placeholder={strings.add_condition_content_hint}
                    placeholderTextColor={Colors.textSecondary}
                    content={condition.item.content}
                    color={Colors.textPrimary}
                    multiline
                    textAlign="left"
                    rows={2}
                    onChangeText={(text: React.SetStateAction<string>) =>
                      handleChangeCondition(text.toString(), condition.item.id)
                    }
                    style={{ textAlignVertical: 'top' }}
                  />
                  <View flex style={{ justifyContent: 'flex-end' }}>
                    <Pressable onPress={() => handleDeleteNewCondition(condition.item.id)}>
                      <MaterialIcons name="delete-outline" size={24} color={Colors.iconPrimary} />
                    </Pressable>
                  </View>
                </KeyboardAwareScrollView>
              </View>
            )}
          />
          <Pressable
            onPress={handleAddCondition}
            style={[styles.backgroundNone, styles.borderTertiary, { padding: 10, justifyContent: 'center' }]}
          >
            <Text iconSecondary center>
              {strings.add_condition}
            </Text>
          </Pressable>
        </View>
      </ConditionsCard>
    </View>
  );

  return (
    <Dialog visible={true} onDismiss={() => navigation.goBack()}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <View flex padding-15 style={{ borderRadius: 18, backgroundColor: Colors.secondaryBG }}>
          <View row style={{ justifyContent: 'space-between' }}>
            <Text h3 textPrimary>
              {`${containerTypeToString(container.getType(), strings)} ${strings.edit.toLocaleLowerCase()}`}
            </Text>
            {stanceUI}
          </View>
          <View
            style={{
              backgroundColor: container.getType() === ContainerType.STATEMENT_GROUP ? 'transparent' : 'transparent',
              borderRadius: 18,
            }}
          >
            {showParent ?
              <View flex style={{ height: '100%', padding: 10, paddingBottom: 0 }}>
                <Container info={convertRouteParamsToInfo({ loc: route.params.parentLoc, type: route.params.parentType, id: route.params.parentId })} navigation={navigation} parentInfo={null} showHeader={false} showOptions={false} showStanceIndicator={false} />
              </View>
              : null
            }
            <View
              style={{
                backgroundColor: container.getType() === ContainerType.STATEMENT_GROUP ? 'transparent' : Colors.quaternaryBG,
                borderRadius: 18,
                padding: 10,
                paddingTop: 0,
                marginTop: container.getType() === ContainerType.STATEMENT_GROUP ? 0 : 10,
              }}
            >
              <View flex>
                {innerUI}
              </View>
              {container.getType() === ContainerType.STATEMENT_GROUP ?
                <View style={{ marginLeft: -2 }}>
                  <ArgumentThreadHeader stance={parentContainer.getChildStance(container.getPath())} />
                </View> : null}
              {container.getType() === ContainerType.STATEMENT_GROUP ? conditionsUi : null}
              <View row style={{ justifyContent: 'flex-end', width: '100%' }}>
                <TouchableOpacity
                  padding-10
                  onPress={() => {
                    navigation.goBack();
                  }}>
                  <CancelIcon width={24} height={24} fill={Colors.iconPrimary} />
                </TouchableOpacity>
                <TouchableOpacity
                  padding-10
                  onPress={() => {
                    for (const condition of newConditions) {
                      const conditionData = makeContainerInDB(
                        convertInfoToPath({ loc, type, id }),
                        ContainerType.STATEMENT,
                        condition.content,
                        StanceType.NEUTRAL,
                        undefined,
                        undefined,
                        undefined
                      );
                      const previousConditions = container.getChildData();
                      const newConditions = [...previousConditions, { [conditionData.path]: StanceType.NEUTRAL }];
                      container.setChildData(newConditions);
                    }
                    container.pushLocalUpdates();
                    if (
                      parentAvailable &&
                      route.params &&
                      convertInfoToPath({ loc: route.params.parentLoc, type: route.params.parentType, id: route.params.parentId })
                    ) {
                      parentContainer.pushLocalUpdates();
                    }
                    navigation.goBack();
                  }}>
                  <CreateIcon width={24} height={24} fill={Colors.iconAccent} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Dialog>
  );
}

type EditStatementDialogProps = {
  statement: StatementData;
};

function EditStatementDialog(props: EditStatementDialogProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const statement = props.statement;
  // Create local state for the TextArea value
  const [content, setContent] = useState(statement.getContent());

  return (
    <View padding-10 paddingB-0>
      <KeyboardAwareScrollView>
        <TextField
          value={content}
          paddingT-10
          placeholder={strings.add_statement_content_hint}
          placeholderTextColor={Colors.textSecondary}
          content
          color={Colors.textPrimary}
          multiline
          textAlign="left"
          numberOfLines={4}
          onChangeText={(text: string) => {
            setContent(text);
            statement.setContent(text);
          }}
          style={{ textAlignVertical: 'top' }}
        />
      </KeyboardAwareScrollView>
    </View>
  );
}

type EditSourceDialogProps = {
  source: SourceData;
};

function EditSourceDialog(props: EditSourceDialogProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const source = props.source;
  // Create local states for the TextInput values
  const [content, setContent] = useState(source.getContent());
  const [url, setUrl] = useState(source.getUrl());
  const [reference, setReference] = useState(source.getReference());

  // We use local states for TextInput values to ensure that the UI is reactive.
  // When we update the state, the component re-renders with the new state.
  // This is important for TextInput because it needs to show the updated text as the user types.
  return (
    <View padding-10 paddingB-0>
      <KeyboardAwareScrollView>
        <TextField
          value={content}
          paddingT-10
          placeholder={strings.add_source_content_hint}
          placeholderTextColor={Colors.textSecondary}
          content
          color={Colors.textPrimary}
          multiline
          textAlign="left"
          numberOfLines={2}
          onChangeText={(text: string) => {
            setContent(text);
            source.setContent(text);
          }}
          style={{ textAlignVertical: 'top' }}
        />
      </KeyboardAwareScrollView>
      <View>
        <TextInput
          value={url}
          placeholder={strings.add_source_url_hint}
          placeholderTextColor={Colors.textSecondary}
          keyboardType="url"
          onChangeText={(text: string) => {
            setUrl(text);
            source.setUrl(text);
          }}
          style={{
            color: Colors.textSeconary,
            flexShrink: 1,
          }}
        />
        {/* <View row centerV>
          <Text marginL-5 flexS>
            {'('}
          </Text>
          <TextInput
            value={reference}
            placeholder={'.....'}
            placeholderTextColor="grey"
            onChangeText={(text: string) => {
              setReference(text);
              source.setReference(text);
            }}
            style={{
              color: 'black',
              flexShrink: 1,
              height: 40,
            }}
          />
          <Text flexG>{')'}</Text>
        </View> */}
      </View>
    </View>
  );
}

type EditCategoryDialogProps = {
  category: CategoryData;
};

function EditCategoryDialog(props: EditCategoryDialogProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const category = props.category;
  // Create local state for the TextField value
  const [name, setName] = useState(category.getName());

  return (
    <TextField
      value={name}
      marginT-10
      paddingT-10
      placeholder={strings.add_topic_name_hint}
      placeholderTextColor={Colors.textSecondary}
      color={Colors.textPrimary}
      onChangeText={(text: string) => {
        setName(text);
        category.setName(text);
      }}
      style={{ textAlignVertical: 'top', textAlign: 'center' }}
    />
  );
}

type EditArgumentDialogProps = {
  argument: StatementGroupData;
};

function EditArgumentDialog({ argument }: EditArgumentDialogProps): JSX.Element { // TODO Move conditions UI from edit dialog here

  return (
    <View padding-0 paddingB-0>
    </View>
  );
}

type TypesMenuProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  currentType: ContainerType;
  setNewType: (type: ContainerType) => void;
  possibleTypes: ContainerType[];
};

function TypesMenu(props: TypesMenuProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);

  return (
    <ActionSheet
      visible={props.visible}
      dialogStyle={{
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        backgroundColor: Colors.secondaryBG,
      }}
      options={Object.values(ContainerType)
        .filter(
          type =>
            type !== props.currentType &&
            type !== ContainerType.NONE &&
            (props.possibleTypes.length > 0
              ? props.possibleTypes.includes(type)
              : true),
        )
        .map(type => {
          return {
            label: containerTypeToString(type, strings),
            onPress: () => {
              props.setNewType(type);
              props.setVisible(false);
            },
          };
        })}
      onDismiss={() => props.setVisible(false)}
    />
  );
}

export function NotYetImplementedDialog(
  feature_name: string,
  strings: React.ContextType<typeof LocalizedStringsContext>,
): void {
  alert(
    feature_name[0].toUpperCase() + feature_name.slice(1),
    strings.not_yet_implemented + '\n\n' + strings.interested_question,
    strings.cancel,
    () => { },
    strings.interested,
    // TODO: Implement interested button.
    () => { },
  );
}

export function showDeleteDialog(
  action: () => void,
  strings: React.ContextType<typeof LocalizedStringsContext>,
): void {
  alert(
    strings.delete,
    strings.delete_question,
    strings.cancel,
    () => { },
    strings.yes,
    action
  );
}

export function showLinkDialog(
  link: string,
  strings: React.ContextType<typeof LocalizedStringsContext>,
): void {
  alert(strings.link, strings.link_question + '\n\n' + link,
    strings.cancel,
    () => { },
    strings.yes,
    () => {
      if (Platform.OS === 'web') {
        Linking.openURL(link)
      } else {
        WebBrowser.openBrowserAsync(link);
      }
    },
  );
}

export type ConflictModalProps = {
  show: boolean,
  handleClose: Function,
  containerType: ContainerType,
  conflicts: any
}


export const ConflictModal: FC<ConflictModalProps> = ({
  show,
  handleClose,
  containerType,
  conflicts
}) => {

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        () => handleClose();
      }}>
      <View style={styles.centeredView}>
        <View style={[
          styles.modalView,
          isMobile ? styles.modalViewMobile : null,
          isTablet ? styles.modalViewTablet : null,
          isDesktop ? styles.modalViewDesktop : null
        ]}>
          <View row style={styles.modalHeader}>
            <Text style={styles.textHeader}>
              {strings.conflicts}
            </Text>
            <Pressable onPress={() => handleClose()}>
              <CancelIcon fill={Colors.iconSecondary} />
            </Pressable>
          </View>
          <View style={styles.modalBody}>
            <FlatList
              data={conflicts}
              renderItem={(conflict) => <ConflictAlert type={conflict.item.type} />}
              keyExtractor={conflict => conflict.type.toString()}
            />
          </View>
        </View>
      </View>
    </Modal>
  )

}

export type ConflictAlertProps = {
  type: ConflictType,
}
export const ConflictAlert: FC<ConflictAlertProps> = ({
  type
}) => {

  let severity: ConflictSeverity = ConflictSeverity.NONE;
  let alertText;
  let resolvable: boolean | undefined = undefined;
  let infoText: string = "No info text";
  switch (type) {
    case ConflictType.NO_CONFLICT:
      alertText = "Kein Konflikt";
      severity = ConflictSeverity.NONE;
      resolvable = false;
      break;
    case ConflictType.SUPPORTING_ARGUMENTS_NOT_FOUND:
      alertText = strings.supporting_arguments_not_found;
      severity = ConflictSeverity.CRITICAL;
      resolvable = true;
      infoText = strings.supporting_arguments_check_info;
      break;
    case ConflictType.SOURCE_NOT_CHECKED:
      alertText = strings.source_not_checked;
      severity = ConflictSeverity.MINOR;
      infoText = strings.source_check_info;
      resolvable = false;
      break;
    case ConflictType.SOURCE_NOT_VALID:
      alertText = strings.source_invalid;
      infoText = strings.source_check_info;
      severity = ConflictSeverity.MAJOR;
      resolvable = true;
      break;
    case ConflictType.LANGUAGE_NOT_OBJECTIVE:
      alertText = strings.language_unobjective;
      infoText = strings.language_check_info;
      severity = ConflictSeverity.MINOR;
      resolvable = true;
      break;
    case ConflictType.RELEVANT_ARGUMENTS_UNSEEN:
      alertText = strings.relevant_arguments_unseen;
      infoText = strings.relevant_arguments_check_info;
      severity = ConflictSeverity.MINOR;
      resolvable = true;
      break;
    case ConflictType.RELEVANT_ARGUMENTS_SEEN:
      alertText = strings.relevant_arguments_seen;
      infoText = strings.relevant_arguments_check_info;
      severity = ConflictSeverity.NONE;
      resolvable = false;
      break;
    case ConflictType.SUPPORTING_ARGUMENTS_FOUND:
      alertText = strings.supporting_arguments_found;
      infoText = strings.supporting_arguments_check_info;
      severity = ConflictSeverity.NONE;
      resolvable = false;
      break;
    case ConflictType.LANGUAGE_OBJECTIVE:
      alertText = strings.language_objective;
      infoText = strings.language_check_info;
      severity = ConflictSeverity.NONE;
      resolvable = false;
      break;
    case ConflictType.SOURCE_VALID:
      alertText = strings.source_valid;
      infoText = strings.source_check_info;
      severity = ConflictSeverity.NONE;
      resolvable = false;
      break;
    case ConflictType.LANGUAGE_NOT_CHECKED:
      alertText = strings.language_not_checked;
      infoText = strings.language_check_info;
      severity = ConflictSeverity.MINOR;
      resolvable = false;
      break;
    case ConflictType.RELEVANT_ARGUMENTS_NOT_CHECKED:
      alertText = strings.relevant_arguments_not_checked;
      infoText = strings.relevant_arguments_check_info;
      severity = ConflictSeverity.MINOR;
      resolvable = false;
      break;
    case ConflictType.DUPLICATE_NOT_CHECKED:
      alertText = strings.duplicates_not_checked;
      infoText = strings.duplicate_check_info;
      severity = ConflictSeverity.MINOR;
      resolvable = false;
      break;
    case ConflictType.DUPLICATE_FOUND:
      alertText = strings.duplicate_check_failed;
      infoText = '';
      severity = ConflictSeverity.CRITICAL;
      resolvable = false;
      break;
    case ConflictType.DUPLICATE_NOT_FOUND:
      alertText = strings.duplicate_check_successful;
      infoText = '';
      severity = ConflictSeverity.NONE;
      resolvable = false;
      break;
    case ConflictType.SUPPORTING_ARGUMENTS_NOT_CHECKED:
      alertText = strings.supporting_arguments_not_checked;
      infoText = strings.supporting_arguments_check_info;
      severity = ConflictSeverity.MINOR;
      resolvable = false;
      break;
    default:
      alertText = "Kein Konflikt";
      severity = ConflictSeverity.NONE;
      break;
  }

  const getIcon = () => {
    switch (severity) {
      case ConflictSeverity.CRITICAL:
        return (
          <CrossIcon style={{ paddingRight: 5 }} fill={Colors.redFG} />
        );
      case ConflictSeverity.MAJOR:
        return (
          <CrossIcon style={{ paddingRight: 5 }} fill={Colors.redFG} />
        );
      case ConflictSeverity.MINOR:
        return (
          <ExclamationMarkIcon style={{ paddingRight: 5 }} fill={Colors.yellowFG} />
        );
      default:
        return (
          <CheckmarkIcon style={{ paddingRight: 5 }} fill={Colors.greenFG} />
        );
    }

  }

  let alertStyle;
  switch (severity) {
    case ConflictSeverity.CRITICAL:
      alertStyle = [styles.conflictAlert, styles.conflictAlertCritical];
      break;
    case ConflictSeverity.MAJOR:
      alertStyle = [styles.conflictAlert, styles.conflictAlertMajor];
      break;
    case ConflictSeverity.MINOR:
      alertStyle = [styles.conflictAlert, styles.conflictAlertMinor];
      break;
    default:
      alertStyle = [styles.conflictAlert, styles.conflictAlertNoConflict];
      break;
  }

  const getResolveButton = () => {
    if (resolvable) {
      switch (severity) {
        case ConflictSeverity.CRITICAL:
          return (
            <Pressable
              style={[styles.resolveButton, styles.resolveButtonCritical]}
              onPress={() => NotYetImplementedDialog('Conflict resolution', strings)}
            >
              <Text style={styles.buttonSecondaryText}>
                {strings.resolve_conflict}
              </Text>
            </Pressable>
          );
        case ConflictSeverity.MAJOR:
          return (
            <Pressable style={[styles.resolveButton, styles.resolveButtonMajor]}>
              <Text style={styles.buttonSecondaryText}>
                {strings.resolve_conflict}
              </Text>
            </Pressable>
          );
        case ConflictSeverity.MINOR:
          return (
            <Pressable style={[styles.resolveButton, styles.resolveButtonMinor]}>
              <Text style={styles.buttonSecondaryText}>
                {strings.resolve_conflict}
              </Text>
            </Pressable>
          );
        default:
          return (<></>);
      }
    }
  }

  return (
    <View row style={[alertStyle, { justifyContent: "space-between", alignItems: "center" }]}>
      <View flex>
        <View row style={{ alignItems: "center" }}>
          <View style={{ padding: 2 }}>
            {getIcon()}
          </View>
          <Text style={{ fontWeight: '600' }}>{alertText}</Text>
        </View>
        <Text>{infoText}</Text>
      </View>
      <View padding-3>
        {getResolveButton()}
      </View>
    </View>
  );

}


export type PublishModalProps = {
  show: boolean,
  handleClose: Function,
  containerType: ContainerType,
  conflicts: any,
  statementInfo: ElementPath,
  parentInfo: ElementPath,
  navigation: any
}

export const PublishModal: FC<PublishModalProps> = ({
  show,
  handleClose,
  containerType,
  conflicts,
  statementInfo,
  parentInfo,
  navigation
}) => {

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const [relevantChecks, setRelevantChecks] = useState<{ type: ConflictType, severity: ConflictSeverity }[]>(conflicts ?
    conflicts.filter((conflict: any) =>
      conflict.type === ConflictType.SUPPORTING_ARGUMENTS_FOUND ||
      conflict.type === ConflictType.SUPPORTING_ARGUMENTS_NOT_CHECKED ||
      conflict.type === ConflictType.SUPPORTING_ARGUMENTS_NOT_FOUND ||
      conflict.type === ConflictType.DUPLICATE_NOT_FOUND ||
      conflict.type === ConflictType.DUPLICATE_NOT_CHECKED ||
      conflict.type === ConflictType.DUPLICATE_FOUND
    )
    : []
  );

  const checkDuplicates = () => {
    const languageCheckResult: Number = -1;
    const newRelevantChecks = conflicts ? conflicts.filter((conflict: any) =>
      conflict.type === ConflictType.SUPPORTING_ARGUMENTS_FOUND ||
      conflict.type === ConflictType.SUPPORTING_ARGUMENTS_NOT_CHECKED ||
      conflict.type === ConflictType.SUPPORTING_ARGUMENTS_NOT_FOUND
    ) : [];
    switch (languageCheckResult) {
      case 1:
        newRelevantChecks.push({
          type: ConflictType.DUPLICATE_NOT_FOUND,
          severity: ConflictSeverity.NONE
        })
        break;
      case 0:
        newRelevantChecks.push({
          type: ConflictType.DUPLICATE_FOUND,
          severity: ConflictSeverity.CRITICAL
        })
        break;
      default:
        newRelevantChecks.push({
          type: ConflictType.DUPLICATE_NOT_CHECKED,
          severity: ConflictSeverity.NONE
        })
        break;
    }
    setRelevantChecks(newRelevantChecks);
  }

  useEffect(() => {
    if (conflicts && conflicts.length > 1) {
      checkDuplicates();
    }
  }, [show])

  const publishStatement = () => {
    const relevantChecksFailed = relevantChecks.filter((check: any) => check.type === ConflictType.SUPPORTING_ARGUMENTS_NOT_FOUND).length > 0;
    if (!relevantChecksFailed) {
      publishSubtree(statementInfo)
        .then(() => navigation.navigate('Feed'));
    } else {
      alert(strings.publish, strings.checks_failed, strings.cancel, () => { }, strings.okay, () => { });
    }
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        () => handleClose();
      }}>
      <View style={styles.centeredView}>
        <View style={[
          styles.modalView,
          isMobile ? styles.modalViewMobile : null,
          isTablet ? styles.modalViewTablet : null,
          isDesktop ? styles.modalViewDesktop : null
        ]}>
          <View row style={styles.modalHeader}>
            <Text style={styles.textHeader}>
              {strings.publish_statement}
            </Text>
            <Pressable onPress={() => handleClose()}>
              <CancelIcon fill={Colors.iconSecondary} />
            </Pressable>
          </View>
          <View style={styles.modalBody}>
            <View style={{ marginVertical: 5 }}>
              <Container
                info={statementInfo}
                parentInfo={parentInfo}
                navigation={navigation}
              />
            </View>
            <FlatList
              data={relevantChecks}
              renderItem={(conflict) => <ConflictAlert type={conflict.item.type} />}
              keyExtractor={conflict => conflict.type.toString()}
            />
          </View>
          <View style={styles.modalFooter}>
            <Pressable onPress={publishStatement} >
              <LinearGradient colors={gradientPink} style={styles.buttonPrimary}>
                <Text style={styles.buttonPrimaryText}>
                  {strings.publish}
                </Text>
              </LinearGradient>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
}