import "@expo/match-media"
import { useMediaQuery } from "react-responsive"

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
export const NotMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

export const useIsMobile = () => {
  return useMediaQuery({ maxWidth: 767 });
}
export const useIsNotMobile = () => {
  return useMediaQuery({ minWidth: 768 });
}
export const useIsTablet = () => {
  return useMediaQuery({ minWidth: 768, maxWidth: 991 });
}
export const useIsDesktop = () => {
  return useMediaQuery({ minWidth: 992 });
}