import { Alert, Platform } from "react-native";
import { View, Text } from "react-native-ui-lib";
import Swal from 'sweetalert2';
import { Image } from 'react-native';
import SadNietzsche from '../../assets/res/sadNietzscheCropped.png';
import SadSocrates from '../../assets/res/sadSocratesCropped.png';
import { strings } from "../localization/localization.web";
import { useIsMobile } from "../MediaQueries";

export function alert(title: string, message?: string, cancelButtonText?: string, cancelButtonAction?: Function, confirmButtonText?: string, confirmButtonAction?: Function): any {
  if (Platform.OS === 'web') {
    const content = !message ? title : message;
    const result = window.confirm([content].filter(Boolean).join('\n'))
    if (result && confirmButtonAction) {
      confirmButtonAction();
    }
    // Swal.fire({
    //   titleText: title,
    //   text: message,
    //   showCancelButton: cancelButtonText !== undefined,
    //   cancelButtonText: cancelButtonText,
    //   showConfirmButton: confirmButtonText !== undefined,
    //   confirmButtonText: confirmButtonText,
    //   reverseButtons: true,
    //   customClass: {
    //     container: "class-container",
    //   }
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     confirmButtonAction();
    //   } else {
    //     cancelButtonAction();
    //   }
    // });
  } else {
    return Alert.alert(title, message, [
      { text: cancelButtonText, onPress: () => cancelButtonAction(), style: 'cancel' },
      { text: confirmButtonText, onPress: () => confirmButtonAction() },
    ]);
  }
}

export function LogInForFeatureAlert(): any {
  return alert(strings.login_to_use_this_feature);
}

export function NothingHereInformation({ text }: { text: string }): JSX.Element {

  const isMobile = useIsMobile();

  const options = [
    {
      label: strings.nothing_here,
      image: SadSocrates,
    },
    // {
    //   label: strings.nothing_here_funny_socrates,
    //   image: SadSocrates,
    // },
    // {
    //   label: strings.nothing_here_funny_nietzsche,
    //   image: SadNietzsche,
    // }
  ];

  const randomOption = options[Math.floor(Math.random() * options.length)];

  return (
    <View flex centerH style={{ justifyContent: isMobile ? 'flex-start' : 'center', marginTop: isMobile ? 20 : 0, padding: 20 }}>
      <Image source={randomOption.image} style={{
        width: isMobile ? 200 : 350,
        height: isMobile ? 200 : 350,
        borderRadius: 20, opacity: 1,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        padding: 20
      }} />
      <Text h2 textPrimary marginT-10 center>
        {randomOption.label}
      </Text>
      <Text h5 textSecondary center>
        {text}
      </Text>
    </View>
  );
}