import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { User, createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase, getAuth, onAuthStateChanged, sendPasswordResetEmail as sendPasswordResetEmailFirebase, signInWithEmailAndPassword, signInAnonymously, updateProfile } from "firebase/auth";
import { CollectionReference, DocumentReference, Firestore, Query as FirestoreQuery, QueryDocumentSnapshot, WriteBatch, Timestamp as _Timestap, arrayRemove as _arrayRemove, query as _query, arrayUnion, collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, serverTimestamp, setDoc, startAfter, updateDoc, where, writeBatch } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { CategoryData, ContainerData, SourceData, StatementData, StatementGroupData, _CategoryData, _ContainerData, _SourceData, _StatementData, _StatementGroupData } from "../dataModel";
import { convertPathToInfo, convertToPublicElementInfo, createPathWithUUID, createPublicPathFromId, getIdFromPath, getLocationFromPath } from "../helperFunctions";
import { FirestoreConstants, PathConstants, dbPath, userId, elementId, ElementPath, StanceType, ContainerType } from "../typings/types";

const firebaseConfig = {
  apiKey: "AIzaSyCjSXrrNnbzWtvsfgboXEks0f7fzLTsGp0",
  authDomain: "test-d99d9.firebaseapp.com",
  projectId: "test-d99d9",
  storageBucket: "test-d99d9.appspot.com",
  messagingSenderId: "8815986443",
  appId: "1:8815986443:web:0776ca2a84581a6bba040e",
  measurementId: "G-W2CTG9BHS6"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const functions = getFunctions(firebaseApp);

export function getUserId(): userId {
  const userId = getAuth().currentUser?.uid;
  if (userId === undefined) {
    throw new Error('User is not logged in.');
  }
  return userId;
}

export type Query = FirestoreQuery;

export type Timestamp = _Timestap;

export const ServerTimestampNow = () => serverTimestamp();
// TODO: This is a workaround for a bug in the firebase typings but would allow the user to set the timestamp to any value.
export const TimestampNow = () => _Timestap.now();

export type ServerTimestamp = typeof TimestampNow;

export const arrayRemove = _arrayRemove;

export const PublishedListQuery: () => Query = () => {
  return _query(getFeedElementsRef(),
    where('parentPaths', '==', []),
    where('userId', '==', getUserId()),
    orderBy('creationTime', 'desc'));
}

function getBookmarkedRefForUser(): CollectionReference {
  return collection(getUserDataRef(getUserId()), FirestoreConstants.BOOKMARKS);
}

export const BookmarkedListQuery: () => Query = () => {
  return _query(getBookmarkedRefForUser(), orderBy('creationTime', 'desc'));
}

export async function addBookmark(info: ElementPath): Promise<boolean> {
  return setDoc(doc(getBookmarkedRefForUser(), info.id), { creationTime: ServerTimestampNow() })
    .then(() => true)
    .catch(() => false);
}

export async function removeBookmark(info: ElementPath): Promise<boolean> {
  return deleteDoc(doc(getBookmarkedRefForUser(), info.id))
    .then(() => true)
    .catch(() => false);
}

export async function toggleBookmark(
  info: ElementPath,
): Promise<{ [key: string]: boolean }> {
  const bookmarkedRef = doc(getBookmarkedRefForUser(), info.id);
  const bookmarked = await getDoc(bookmarkedRef);
  const response = { add: false, remove: false };
  if (bookmarked.exists) {
    response.remove = await removeBookmark(info);
  } else {
    response.add = await addBookmark(info);
  }
  return response;
}

// Called once a user registered to check if the base structure of their data exists in the database.
// If it does not, it is created.
export const initializeUserData = async (userId: userId, userName: string, userEmail: string) => {
  const userData = await getDoc(getUserDataRef(userId));

  if (!userData.exists()) {
    await setDoc(getUserDataRef(userId), {});

    const personalElementsRef = collection(getUserDataRef(userId), FirestoreConstants.PERSONAL_ELEMENTS);
    await setDoc(doc(personalElementsRef, FirestoreConstants.ROOT_ID), { childPaths: [] });

    setDoc(doc(collection(getUserDataRef(userId), FirestoreConstants.DATA), FirestoreConstants.PUBLIC_USER_DATA), { userName: userName });
    setDoc(doc(collection(getUserDataRef(userId), FirestoreConstants.DATA), FirestoreConstants.PRIVATE_USER_DATA), { email: userEmail });
  }
};

export async function saveContainerToDB(container: ContainerData, stance: StanceType) {
  // TODO: Implement feedback for Errors.
  // TODO: prevent overriding of existing containers or at least make the container consistent and not mix properties of the old and new container. The former might require transactions instead of batch writes.
  if (container.getParentPaths().length === 0) {
    return;
  }

  const batch = writeBatch(getFirestore());

  batch.set(getRefFromDB(container.getInfo()), container.getData());

  // TODO: For now, updating childs is too complicated as it requires knowledge about the stance of each child to the new parent. Could simply be neutral but probably will not need this right now.
  // for(const childPath of container.getChildPaths()) {
  //     batch.update(getPersonalStatementsRef().doc(childPath), {parentPaths: firestore.FieldValue.arrayUnion(container.getId())});
  // }

  // TODO: Missing proper handling of public/feed saves.
  for (const parentInfo of container.getParentInfos()) {
    const childPath: { [key: dbPath]: StanceType } = {};
    childPath[container.getPath()] = stance;
    batch.update(getRefFromDB(parentInfo), {
      childPaths: arrayUnion(childPath),
    });
  }

  batch.commit().catch(error => {
    console.error('Error saving container to DB: ', error);
  });
}

/**
 * Hook for getting the current user, which is updated when the user changes (logged in/out, different user).
 *
 * @returns [currentUser, setCurrentUser] Pair of the current user and a function to set the current user.
 */
export function useUserState(): [User | null, boolean] {
  const [currentUser, setCurrentUser] = useState(getAuth().currentUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const subscriber = getAuth().onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });
    return subscriber; // unsubscribe on unmount
  }, []);

  return [currentUser, loading];
}

export function useLoggedInState(): [boolean, boolean] {
  const [userState, loading] = useUserState();
  return [userState !== null, loading];
}

export function useUserName(userId: userId = getUserId()): [string, boolean] {
  const [userName, setUserName] = useState('unknown user');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserName(userId).then(name => {
      setUserName(name);
      setLoading(false);
    }), []
  });

  return [userName, loading];
}

export function useUserEmail(): string {
  const [userEmail, setUserEmail] = useState('unknown email');

  useEffect(() => {
    getUserEMail().then(email => {
      setUserEmail(email);
    }), []
  });

  return userEmail;
}

function getUserDataRef(userId: userId = getUserId()): DocumentReference {
  return doc(getFirestore(), FirestoreConstants.USER_DATA, userId);
}

function getUserPersonalElementsRef(userId: userId = getUserId()): CollectionReference {
  return collection(getUserDataRef(userId), FirestoreConstants.PERSONAL_ELEMENTS);
}

function getPublicDataRef(): CollectionReference {
  return collection(getFirestore(), FirestoreConstants.PUBLIC_DATA);
}

function getFeedRef(): DocumentReference {
  return doc(getPublicDataRef(), FirestoreConstants.FEED);
}

function getFeedElementsRef(): CollectionReference {
  return collection(getFeedRef(), FirestoreConstants.FEED_ELEMENTS);
}

function getFeedRootElementsRef(): CollectionReference {
  return collection(getFeedRef(), FirestoreConstants.FEED_ROOT_ELEMENTS);
}

function getRootElementsRef(path: dbPath): CollectionReference {
  if (path === PathConstants.PUBLIC) {
    return getFeedRootElementsRef();
  } else {
    throw new Error('Invalid path: ' + path);
  }
}

function getRefFromDB(info: ElementPath): DocumentReference {
  switch (info.loc) {
    case PathConstants.PERSONAL:
      return doc(getUserPersonalElementsRef(info.user), info.id);
    case PathConstants.PUBLIC:
      return doc(getFeedElementsRef(), info.id);
    case PathConstants.GROUP:
      throw new Error('Group paths not implemented yet.');
    default:
      throw new Error('Invalid element: ' + info);
  }
}

export async function getContainerDataFromDB<T extends ContainerData>(info: ElementPath): Promise<T> {
  return getDoc(getRefFromDB(info))
    .then(doc => {
      if (doc.exists) {
        return new ContainerData(doc.data() as _ContainerData) as T;
      }
      throw new Error('Document does not exist.');
    });
}

export async function publishSubtree(info: ElementPath): Promise<void> {
  const batch = writeBatch(getFirestore());

  const rootContainer = await ContainerData.getFromDB(info);
  const childPaths = [...rootContainer.getChildPaths()];
  rootContainer.setParentPaths([]);

  batch.set(
    getRefFromDB(convertToPublicElementInfo(info)),
    rootContainer.convertToPublicData().getData(),
  );

  // This does not terminate if there is a cycle in the tree.
  // Must be fixed in the future by checking if a path has already been visited.
  while (childPaths.length > 0) {
    const childPath = childPaths.pop();
    const childContainer = await ContainerData.getFromDB(convertPathToInfo(childPath));
    childPaths.push(...childContainer.getChildPaths());
    batch.set(
      getRefFromDB(convertToPublicElementInfo(convertPathToInfo(childPath))),
      childContainer.convertToPublicData().getData(),
    );
  }

  batch
    .commit()
    .then(() => {
      Promise.resolve();
    })
    .catch(error => {
      console.error('Error publishing subtree: ', error);
      Promise.reject(error);
    });
}

export async function getUserName(userId: userId = getUserId()): Promise<string> {
  const userDataReference = collection(getUserDataRef(userId), FirestoreConstants.DATA);
  const publicUserDataReference = doc(userDataReference, FirestoreConstants.PUBLIC_USER_DATA);
  const publicUserData = await getDoc(publicUserDataReference);
  return publicUserData.data()?.userName;
}

export async function getUserEMail(userId: userId = getUserId()): Promise<string> {
  const userDataReference = collection(getUserDataRef(userId), FirestoreConstants.DATA);
  const privateUserDataReference = doc(userDataReference, FirestoreConstants.PRIVATE_USER_DATA);
  const privateUserData = await getDoc(privateUserDataReference);
  return privateUserData.data()?.email;
}

/**
!!!
Currently accepts a collection of ids and converts them to public paths.
Therefore, it only works for public elements right now.
Also, it is customized for bookmarks, published elements and feed elements.
It likely requires refactoring to be more generic.
*/
export function useLazyElementsLoader(
  query: Query,
  initialEntries = 10,
): {
  infos: ElementPath[];
  loading: boolean;
  error: Error | undefined;
  refresh: () => void;
  loadMoreElements: (numberOfElements: number) => void;
} {
  const [infos, setRootInfos] = useState([] as ElementPath[]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined as Error | undefined);

  const [lastDoc, setLastDoc] = useState(
    undefined as QueryDocumentSnapshot | undefined,
  );
  const [firstDoc, setFirstDoc] = useState(
    undefined as QueryDocumentSnapshot | undefined,
  );
  const [endReached, setEndReached] = useState(false);

  const loadElements = (numOfElements: number, reload = false) => {
    // TODO: Also needs improved reloading. Currently, it reloads the entire feed, which is not necessary.
    if (endReached && !reload) {
      return;
    }
    if (loading) {
      return;
    } else {
      setLoading(true);
    }

    if (reload) {
      setLastDoc(undefined);
      setFirstDoc(undefined);
      setEndReached(false);
      setError(undefined);
    } else if (lastDoc) {
      query = _query(query, startAfter(lastDoc));
    }

    const newInfos: ElementPath[] = [];

    getDocs(query)
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          newInfos.push(convertPathToInfo(createPublicPathFromId(doc.id)));
        });
        if (newInfos.length > 0) {
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstDoc(querySnapshot.docs[0]);
          if (reload) {
            setRootInfos(newInfos);
          } else {
            setRootInfos(prevInfos => [...prevInfos, ...newInfos]);
          }
        } else {
          setEndReached(true);
        }
      })
      .catch(error => {
        console.error('Error getting documents: ', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refresh = () => {
    loadElements(initialEntries, true);
  };

  useEffect(() => {
    loadElements(initialEntries, true);
  }, []);

  return {
    infos: infos,
    loading,
    error,
    refresh,
    loadMoreElements: loadElements,
  };
}

// Legacy but might be useful in the future for the more generic useLazyElementsLoader.
// export function useFeedLoader(initialEntries = 10): {
//   rootPaths: dbPath[];
//   loading: boolean;
//   error: Error | undefined;
//   reloadFeed: () => void;
//   loadMoreEntries: (numberOfEntries: number) => void;
// } {
//   const [rootPaths, setRootPaths] = useState([] as uuid[]);

//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(undefined as Error | undefined);

//   const [lastDoc, setLastDoc] = useState(
//     undefined as QueryDocumentSnapshot | undefined,
//   );
//   const [firstDoc, setFirstDoc] = useState(
//     undefined as QueryDocumentSnapshot | undefined,
//   );
//   const [endReached, setEndReached] = useState(false);

//   const loadEntries = async (numberOfEntries: number, reload = false) => {
//     // TODO: Will need ordering since order is not guaranteed.
//     // TODO: Also needs improved reloading. Currently, it reloads the entire feed, which is not necessary.
//     // BUG: It loads the same entries again when loading more entries. Ordering might fix this.
//     if (endReached && !reload) {
//       return;
//     }
//     if (loading) {
//       return;
//     } else {
//       setLoading(true);
//     }

//     let q = query(getFeedElementsRef(),
//       where('parentPaths', '==', []),
//       orderBy('creationTime', 'desc'),
//       limit(numberOfEntries));

//     if (reload) {
//       setLastDoc(undefined);
//       setFirstDoc(undefined);
//       setEndReached(false);
//       setError(undefined);
//     } else if (lastDoc) {
//       q = query(q, startAfter(lastDoc));
//     }

//     const newPaths: uuid[] = [];

//     try {
//       const querySnapshot = await getDocs(q);
//       // console.log('Query snapshot: ', querySnapshot);
//       querySnapshot.forEach((doc) => {
//         newPaths.push(createPublicPathFromId(doc.id));
//       });
//       if (newPaths.length > 0) {
//         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
//         setFirstDoc(querySnapshot.docs[0]);
//         if (reload) {
//           setRootPaths(newPaths);
//         } else {
//           // console.log('Adding new paths: ', newPaths);
//           setRootPaths(prevRootPaths => [...prevRootPaths, ...newPaths]);
//         }
//       } else {
//         setEndReached(true);
//       }
//     } catch (error: any) {
//       console.log('Error getting documents: ', error);
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const reloadFeed = () => {
//     loadEntries(initialEntries, true);
//   };

//   useEffect(() => {
//     loadEntries(initialEntries, true);
//   }, []);

//   return {
//     rootPaths: rootPaths,
//     loading,
//     error,
//     reloadFeed,
//     loadMoreEntries: loadEntries,
//   };
// }

export function signIn(mail: string, password: string): Promise<void> {
  return signInWithEmailAndPassword(getAuth(), mail, password)
    .then(() => {
      Promise.resolve();
    });
}

export async function signInAnonymousUser(): Promise<void> {
  return signInAnonymously(getAuth())
    .then(() => {
      Promise.resolve();
    });
}

export function signOut(): Promise<void> {
  return getAuth().signOut();
}

export function sendPasswordResetEmail(mail: string): Promise<void> {
  return sendPasswordResetEmailFirebase(getAuth(), mail);
}

export function createUser(mail: string, password: string, userName: string): Promise<void> {
  return createUserWithEmailAndPasswordFirebase(getAuth(), mail, password)
    .then(userCredentials => {
      const p1 = updateProfile(userCredentials.user, { displayName: userName });
      const p2 = initializeUserData(userCredentials.user.uid, userName, mail);
      return Promise.all([p1, p2]);
    })
    .then(() => {
      Promise.resolve();
    });
}

export function makeContainerInDB(parentPath: dbPath, typeId: ContainerType, content: string, stance: StanceType, url: string, reference: string, name: string, conditions?: string[]) {
  const userId = getUserId();
  const path = createPathWithUUID(getLocationFromPath(parentPath), typeId);
  switch (typeId) {
    case ContainerType.STATEMENT: {
      const statementData: _StatementData = {
        content: content,
        type: typeId,
        path: path,
        userId: userId,
        parentPaths: [parentPath],
        childPaths: [],
        votes: {},
        creationTime: TimestampNow(),
      };
      StatementData.makeInDB(statementData, stance);
      return statementData;
      break;
    }
    case ContainerType.STATEMENT_GROUP: {
      const statementGroupData: _StatementGroupData = {
        type: typeId,
        path: path,
        userId: userId,
        parentPaths: [parentPath],
        childPaths: [],
        votes: {},
        creationTime: TimestampNow(),
      };
      StatementGroupData.makeInDB(statementGroupData, stance);
      conditions.forEach((condition) => {
        const conditionPath = createPathWithUUID(getLocationFromPath(path), ContainerType.STATEMENT);
        const conditionData: _StatementData = {
          content: condition,
          type: ContainerType.STATEMENT,
          path: conditionPath,
          userId: userId,
          parentPaths: [path],
          childPaths: [],
          votes: {},
          creationTime: TimestampNow()
        };
        StatementData.makeInDB(conditionData, StanceType.NEUTRAL);
      });
      return statementGroupData;
      break;
    }
    case ContainerType.SOURCE: {
      const sourceData: _SourceData = {
        content: content,
        url: url,
        reference: reference,
        type: typeId,
        path: path,
        userId: userId,
        parentPaths: [parentPath],
        childPaths: [],
        votes: {},
        creationTime: TimestampNow(),
      };
      SourceData.makeInDB(sourceData, stance);
      break;
    }
    case ContainerType.CATEGORY: {
      const categoryData: _CategoryData = {
        name: name,
        type: typeId,
        path: path,
        userId: userId,
        parentPaths: [parentPath],
        childPaths: [],
        votes: {},
        creationTime: TimestampNow(),
      };
      CategoryData.makeInDB(categoryData, stance);
      break;
    }
  }
};

export async function getNumOfTutorialSlides(): Promise<number> {
  const pathToSlides = FirestoreConstants.TUTORIAL_SLIDES_PATH;
  return getCountFromServer(collection(getFirestore(), pathToSlides))
    .then(querySnapshot => {
      return querySnapshot.data().count;
    });
}

export async function getTutorialSlideData(slidePath: string): Promise<{ text: string, imageURL: string }> {
  return getDoc(doc(getFirestore(), slidePath))
    .then(doc => {
      return { text: doc.data()?.text, imageURL: doc.data()?.imageURL };
    });
}

export async function getTutorialSlideImage(imageURL: string): Promise<string> {
  return getDownloadURL(ref(getStorage(), imageURL));
}

export async function isUserMaintainer(): Promise<boolean> {
  try {
    const userId = getUserId();
    return getDoc(doc(collection(getFirestore(), FirestoreConstants.MAINTAINERS), userId))
      .then(doc => {
        // If the document exists, then the user is a maintainer
        return doc.exists() ? true : false;
      });
  } catch (error) {
    return false;
  }
}

export function onAuthStateChangedListener(callback: () => void) {
  return onAuthStateChanged(getAuth(), callback);
}

export async function updateData<T extends ContainerData>(info: ElementPath, data: {}): Promise<void> {
  return updateDoc(getRefFromDB(info), data);
}

export class Batch {
  private batch: WriteBatch;

  constructor() {
    this.batch = writeBatch(getFirestore());
  }

  public set(info: ElementPath, data: {}): Batch {
    this.batch.set(getRefFromDB(info), data);
    return this;
  }

  public update(info: ElementPath, data: {}): Batch {
    this.batch.update(getRefFromDB(info), data);
    return this;
  }

  public delete(info: ElementPath): Batch {
    this.batch.delete(getRefFromDB(info));
    return this;
  }

  public commit(): Promise<void> {
    return this.batch.commit();
  }
}

export function onContainerDataSnapshot<T extends _ContainerData>(info: ElementPath, callback: (data: T) => void, errorHandler: (error: Error) => void): () => void {
  try {
    return onSnapshot(getRefFromDB(info), doc => {
      if (doc.exists) {
        callback(doc.data() as T);
      } else {
        errorHandler(new Error('Document does not exist.'));
      }
    });
  } catch (error) {
    errorHandler(new Error('Error getting document: ' + error));
    return () => { };
  }
}

export function getFeedElementsQuery(): Query {
  return _query(getFeedElementsRef(),
    where('parentPaths', '==', []),
    orderBy('creationTime', 'desc'),
    limit(10))
}

export function getPersonalStatementsQuery(): Query { // TODO Not working yet: returns list with public statements
  return _query(getUserPersonalElementsRef(),
    where('type', '==', ContainerType.STATEMENT),
    where('parentPaths', '==', [FirestoreConstants.ROOT_PATH]),
    orderBy('creationTime', 'desc'),
    limit(10))
}

export function proposeArgument(statement: string, stance: StanceType): Promise<any> {
  const proposeArgument = httpsCallable(functions, 'propose_argument');
  return proposeArgument({ statement: statement, stance: stance })
    .then((result: any) => {
      const data = result.data;
      const proposedArgument = data.proposed_argument;
      return proposedArgument;
    });
}
