import { Button, Text, View } from 'react-native-ui-lib';
import React, { useEffect, useState } from 'react';
import { styles } from '../styles';
import { LocalizedStringsContext } from '../localization/localization.native';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import { getUserEMail, signOut, useUserEmail, useUserName } from '../firebaseWrapper/firebaseWrapper';
import { navigationProps } from '../typings/types';
import { Pressable } from 'react-native';
import { NotYetImplementedDialog } from './Dialogs';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useIsMobile, useIsNotMobile } from '../MediaQueries';

export function Settings({ navigation, route }: navigationProps): JSX.Element {
  const strings = React.useContext(LocalizedStringsContext);
  const [userName] = useUserName();
  const [userEmail, setUserEmail] = useState('unknown email');
  const isMobile = true // useIsMobile();
  // const isMobile = !useIsNotMobile()

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserEmail(user.email)
    }
  });

  const sections = [
    {
      name: strings.account,
      content: [
        {
          label: strings.display_name,
          type: 'text',
          data: userName,
          handlePress: () => { }
        },
        {
          label: strings.email,
          type: 'text',
          data: userEmail,
          handlePress: () => { }
        },
        {
          label: strings.logout,
          type: 'buttonNormal',
          data: undefined,
          style: {},
          handlePress: () => {
            signOut()
              .then(() => {
                window.location.replace('https//:app.lucid-mind.eu/login');
              });
          }
        },
        {
          label: strings.change_password,
          type: 'buttonNormal',
          data: undefined,
          style: {},
          handlePress: () => NotYetImplementedDialog('Changing password', strings)
        },
        {
          label: strings.change_email,
          type: 'buttonNormal',
          data: undefined,
          style: {},
          handlePress: () => NotYetImplementedDialog('Changing email', strings)
        }
      ]
    },
    {
      name: strings.danger_zone,
      content: [
        {
          label: strings.delete_account,
          type: 'buttonDanger',
          data: undefined,
          style: {},
          handlePress: () => NotYetImplementedDialog('Deleting account', strings)
        }
      ]
    }
  ]

  return ( // TODO Use section list instead? https://reactnative.dev/docs/sectionlist
    <View flex bg-primaryBG>
      <ScrollView showsVerticalScrollIndicator={false} style={[styles.body, { marginVertical: 5, marginHorizontal: isMobile ? 0 : 5 }]}>
        {sections.map((section) =>
          <View marginB-10 padding-20 style={styles.settingsSection} key={section.name}>
            <Text style={[styles.sectionHeader, { marginBottom: 5 }]}>
              {section.name}
            </Text>
            {section.content.map((sectionContent) =>
              <>
                {sectionContent.type === 'text' ?
                  <View flex row style={[{ marginBottom: 5, alignItems: 'center' }]} key={sectionContent.label}>
                    <Text style={[{ width: '50%' }, styles.textSecondary]}>
                      {sectionContent.label}
                    </Text>
                    <TextInput
                      style={[{ width: '50%', padding: 5 }, styles.textSecondary, styles.borderSecondary]}
                      value={sectionContent.data}
                      onChangeText={sectionContent.handlePress}
                      keyboardType='default'
                    />
                  </View> : null
                }
              </>
            )}
            <View flex row style={[{ marginBottom: 5, marginTop: 5, flexWrap: 'wrap', rowGap: 5 }]}>
              {section.content.filter((sectionContent) => sectionContent.type === 'buttonNormal' || sectionContent.type === 'buttonDanger').length > 0 ?
                <>
                  {section.content.map((sectionContent) =>
                    <>
                      {sectionContent.type === 'buttonNormal' || sectionContent.type === 'buttonDanger' ?
                        <Pressable
                          onPress={sectionContent.handlePress}
                          style={[
                            styles.buttonSmall,
                            sectionContent.type === 'buttonDanger' ? styles.buttonBorderRed : styles.buttonBorderSecondary,
                            sectionContent.type === 'buttonNormal' && { paddingBottom: 'auto', marginRight: 5, marginBottom: 5 }
                          ]}
                          key={sectionContent.label}
                        >
                          <Text
                            style={[{ width: 'auto' }, sectionContent.type === 'buttonDanger' ? styles.textRed : styles.textSecondary]}
                          >
                            {sectionContent.label}
                          </Text>
                        </Pressable>
                        : null
                      }
                    </>
                  )}
                </>
                : null
              }
            </View>
          </View>
        )}
      </ScrollView>
      <View flex row style={[{
        position: 'absolute',
        right: 0,
        bottom: isMobile ? 45 : 0,
        margin: 15,
        elevation: 5,
        shadowColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
      }]}
      >
        <Pressable
          style={[{ marginHorizontal: 10 }, styles.buttonBorderSecondary, styles.buttonSmall, styles.backgroundSecondary]}
          onPress={() => NotYetImplementedDialog('Changing profile', strings)}
        >
          <Text style={styles.textSecondary}>
            {strings.cancel_changes}
          </Text>
        </Pressable>
        <Pressable
          style={[styles.buttonBorderRed, styles.buttonSmall, styles.backgroundSecondary]}
          onPress={() => NotYetImplementedDialog('Changing profile', strings)}
        >
          <Text style={styles.textRed}>
            {strings.confirm_changes}
          </Text>
        </Pressable>
      </View>
    </View>
  );
}
