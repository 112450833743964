import {en} from '../localization/en';
import {de} from '../localization/de';
import LocalizedStrings from 'react-localization';
import {useEffect, useState, createContext} from 'react';
import * as Localize from 'react-native-localize';
import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

const DEFAULT_LANGUAGE = 'en';

const languages = {en, de};

export const LocalizedStringsContext = createContext(
  new LocalizedStrings(languages),
);

export function LocalizedStringsContextProvider({children}: any): JSX.Element {
  const [language, setLanguage] = useState(
    Localize.findBestAvailableLanguage(Object.keys(languages))?.languageTag ||
      DEFAULT_LANGUAGE,
  );
  const [strings, setString] = useState(new LocalizedStrings(languages));

  useEffect(() => {
    const newStrings = new LocalizedStrings(languages);
    newStrings.setLanguage(language);
    setString(newStrings);

    dayjs.locale(language);

    Localize.addEventListener('change', handleLocalizationChange);
    return () => {
      Localize.removeEventListener('change', handleLocalizationChange);
    };
  }, []);

  const handleLocalizationChange = () => {
    const newLanguage =
      Localize.findBestAvailableLanguage(Object.keys(languages))?.languageTag ||
      DEFAULT_LANGUAGE;
    setLanguage(newLanguage);
    dayjs.locale(newLanguage);
  };

  return (
    <LocalizedStringsContext.Provider value={strings}>
      {children}
    </LocalizedStringsContext.Provider>
  );
}
