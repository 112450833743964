import { FC } from "react";
import { Pressable } from "react-native";
import { Colors, Text } from "react-native-ui-lib";
import { gradientPink, styles } from "../styles";
import LinearGradient from "react-native-linear-gradient";
import { MaterialIcons } from '@expo/vector-icons';

export type ButtonProps = {
    onPress: Function,
    label?: string,
    icon?: any
}

/**
 *
 * @returns Primary button component.
 */
export const ButtonPrimary: FC<ButtonProps> = (
    {
        onPress,
        label,
    }
) => {

    return (
        <Pressable onPress={() => onPress()} >
            <LinearGradient
                colors={gradientPink}
                style={[styles.buttonShadow, styles.buttonPrimary]}
            >
                <Text style={styles.buttonPrimaryText} center>
                    {label}
                </Text>
            </LinearGradient>
        </Pressable>
    )

}

/**
 *
 * @returns Button component with icon.
 */
export const IconButton: FC<ButtonProps> = ({ onPress, icon }) => {

    return (
        <Pressable onPress={() => onPress()} >
            {icon}
        </Pressable>
    )

}

/**
 *
 * @returns Log In button.
 */
export const LogInButton: FC<ButtonProps> = ({ onPress }) => {

    return (
        <IconButton
            onPress={onPress}
            icon={<MaterialIcons name="login" size={30} color={Colors.iconSecondary} />}
        />
    )

}