import React, { createContext, useEffect, useState } from 'react';
import { isUserMaintainer, onAuthStateChangedListener } from '../firebaseWrapper/firebaseWrapper';

export const IsMaintainerContext = createContext<boolean>(false);

interface ProviderProps {
  children: JSX.Element | JSX.Element[];
}

export function IsMaintainerContextProvider({
  children,
}: ProviderProps): JSX.Element {
  const [isMaintainer, setIsMaintainer] = useState<boolean>(false);

  useEffect(() => {
    const setMaintainerStatus = async () => {
      setIsMaintainer(await isUserMaintainer());
    };

    setMaintainerStatus();

    // Listen for changes to the user's sign-in state (e.g., user signs in or signs out)
    const unsubscribe = onAuthStateChangedListener(setMaintainerStatus);

    return unsubscribe;
  }, []);

  return (
    <IsMaintainerContext.Provider value={isMaintainer}>
      {children}
    </IsMaintainerContext.Provider>
  );
}
