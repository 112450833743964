import { Colors, Typography } from 'react-native-ui-lib';
import { StyleSheet } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';

const baseColors = {
  beigeLightest: '#FFFCFA',
  beigeLighter: '#FAF7F4',
  beigeLight: '#F5F0EB',
  beige: '#F2ECE7',
  beigeDark: '#E7DDD4',
  beigeDarker: '#CDBCAD',
  greenLight: '#90E098',
  greenDark: '#2B5732',
  redLight: '#EA9993',
  red: '#F40000',
  redDark: '#ad2e2e',
  yellowDark: '#9B942E',
  yellow: '#DDD373',
  yellowLight: '#F1ECBA',
  greyLighter: '#D7D5D5',
  greyLight: '#C9C4C4',
  grey: '#A09892',
  greyDark: '#888079',
  greyDarker: '#5C5A58',
  greyDarkest: '#444444',
  pinkLighter: '#EEBCD6',
  pinkLight: '#DDA6C2',
  pinkDark: '#BB8BA4',
  pinkDarker: '#AB6E8D',
  pinkDarkest: '#86556E'
};

const lightScheme = {
  primaryBG: baseColors.beigeLightest,
  secondaryBG: baseColors.beigeLighter,
  tertiaryBG: baseColors.beigeLight,
  quaternaryBG: baseColors.beigeLight,
  quinaryBG: baseColors.beigeDarker,
  backgroundAccent: baseColors.pinkLight,
  backgroundAccent2: baseColors.pinkDark,
  primaryFG: '#ede5de',
  logo: '#535353',
  highlight: '#dda6c0',
  greenFG: baseColors.greenDark,
  redFG: baseColors.redDark,
  greenBG: baseColors.greenLight,
  redBG: baseColors.redLight,
  yellowBG: baseColors.yellowLight,
  yellowIcon: baseColors.yellow,
  yellowFG: baseColors.yellowDark,
  activeText: baseColors.greyDarkest,
  inactiveText: baseColors.greyDarker,
  textPrimary: baseColors.greyDarkest,
  textSecondary: baseColors.greyDarker,
  textAccentSecondary: baseColors.beigeLightest,
  textAccentTertiary: baseColors.beigeDarker,
  iconPrimary: baseColors.greyDarker,
  iconSecondary: baseColors.greyDark,
  iconAccent: baseColors.pinkDark,
  skeletonPrimary: baseColors.greyLighter,
  skeletonSecondary: baseColors.greyLight,
  skeletonTertiary: baseColors.grey,
};

Colors.loadSchemes({
  light: lightScheme,
  dark: lightScheme,
});

export const gradientPink = [baseColors.pinkLight, baseColors.pinkDark];

Typography.loadTypographies({
  h1: { fontSize: 32 },
  h2: { fontSize: 24 },
  h3: { fontSize: 18 },
  content: { fontSize: 15 },
  bold: { fontWeight: 'bold' },
  small: { fontSize: 13, lineHeight: 24 },
});

const styles = StyleSheet.create({
  greenButton: {
    borderWidth: 3,
    margin: 5,
    alignItems: 'center',
    alignContent: 'center',
  },
  greenButtonText: {
    fontSize: 20,
    color: Colors.greenFG,
  },
  redButton: {
    borderWidth: 3,
    borderColor: Colors.redFG,
    backgroundColor: Colors.redBG,
    margin: 5,
    alignItems: 'center',
    alignContent: 'center',
  },
  redButtonText: {
    fontSize: 20,
    color: Colors.redFG,
  },
  inputContainer: {
    color: Colors.textPrimary,
    marginHorizontal: 5,
    marginVertical: 3,
    fontSize: 15,
    borderRadius: 50,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.tertiaryBG
  },
  blueInfoText: {
    padding: 10,
    fontSize: 15,
    color: 'blue',
    textAlign: 'center',
  },
  textPrimary: {
    color: Colors.textPrimary
  },
  textAccentSecondary: {
    color: Colors.textAccentSecondary
  },
  textAccentTertiary: {
    color: Colors.textAccentTertiary
  },
  borderPrimary: {
    borderColor: null,
    borderRadius: 20,
  },
  borderSecondary: {
    borderColor: Colors.iconSecondary,
    borderWidth: 2,
    borderRadius: 5
  },
  borderTertiary: {
    borderColor: Colors.iconSecondary,
    borderWidth: 2,
    borderRadius: 20,
  },
  backgroundNone: {
    backgroundColor: null,
  },
  backgroundAccentPrimary: {
    backgroundColor: Colors.backgroundAccent
  },
  backgroundTertiary: {
    backgroundColor: Colors.tertiaryBG
  },
  backgroundQuinary: {
    backgroundColor: Colors.quinaryBG
  },
  body: {
    backgroundColor: Colors.secondaryBG,
    borderRadius: 18,
    padding: 20,
    // margin: 5
    // borderColor: 'red',
    // borderWidth: 3
  },
  test: {
    // backgroundColor: 'red'
  },
  settingsSection: {
    backgroundColor: Colors.tertiaryBG,
    borderRadius: 18,
  },
  sectionHeader: {
    color: Colors.textPrimary,
    fontSize: 20,
    marginBottom: 5
  },
  textSecondary: {
    color: Colors.iconSecondary,
    fontWeight: '600'
  },
  textAccent: {
    color: Colors.quinaryBG,
    fontWeight: '600'
  },
  textRed: {
    color: Colors.redFG,
    fontWeight: 'normal'
  },
  buttonBorderRed: {
    borderColor: Colors.redFG,
  },
  buttonBorderBeige: {
    borderColor: Colors.iconSecondary,
  },
  buttonBorderSecondary: {
    borderColor: Colors.iconSecondary,
  },
  backgroundPrimary: {
    // backgroundColor: Colors.primaryBG
  },
  backgroundSecondary: {
    // backgroundColor: Colors.secondaryBG
  },
  buttonSmall: {
    borderWidth: 2,
    borderRadius: 50,
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  conflictBadge: {
    height: 15,
    width: 15,
    borderRadius: 10,
    // marginTop: 5
  },
  conflictBadgeNoConflict: {
    backgroundColor: Colors.greenBG,
  },
  conflictBadgeCritical: {
    backgroundColor: Colors.redBG,
  },
  conflictBadgeMajor: {
    backgroundColor: Colors.redBG,
  },
  conflictBadgeMinor: {
    backgroundColor: Colors.yellowIcon,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 0,
    backgroundColor: Colors.secondaryBG,
    borderRadius: 18,
    padding: 15,
    // alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalViewMobile: {
    width: "95%"
  },
  modalViewTablet: {
    width: "50%"
  },
  modalViewDesktop: {
    width: "40%"
  },
  modalHeader: {
    marginBottom: 5,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  modalBody: {

  },
  modalFooter: {
    alignItems: 'flex-end',
    marginTop: 5
  },
  iconSmall: {
    color: Colors.iconPrimary
  },
  buttonPrimary: { // For shadows: https://ethercreative.github.io/react-native-shadow-generator/
    alignItems: 'center',
    borderRadius: 20,
    padding: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonPrimaryText: {
    color: Colors.textAccentSecondary,
  },
  buttonPrimaryPressed: {
    backgroundColor: Colors.greyDarkest
  },
  buttonSecondary: {
    backgroundColor: Colors.tertiaryBG,
    borderRadius: 5,
    padding: 5
  },
  buttonSecondaryText: {
    color: Colors.textAccentSecondary,
  },
  buttonSecondaryPressed: {
    backgroundColor: Colors.textPrimary
  },
  textHeader: {
    color: Colors.textPrimary,
    fontSize: 24,
  },
  resolveButton: {
    // borderWidth: 1.5,
    borderRadius: 10,
    padding: 8
  },
  resolveButtonMinor: {
    color: Colors.yellowFG,
    // borderColor: Colors.yellowFG,
    backgroundColor: Colors.yellowFG,
  },
  resolveButtonMajor: {
    color: Colors.redFG,
    // borderColor: Colors.redFG,
    backgroundColor: Colors.redFG,
  },
  resolveButtonCritical: {
    color: Colors.redFG,
    // borderColor: Colors.redFG,
    backgroundColor: Colors.redFG,
  },
  conflictAlert: {
    padding: 10,
    borderRadius: 10,
    marginBottom: 5,
  },
  conflictAlertNoConflict: {
    backgroundColor: Colors.greenBG
  },
  conflictAlertMinor: {
    backgroundColor: Colors.yellowBG


  },
  conflictAlertMajor: {
    backgroundColor: Colors.redBG


  },
  conflictAlertCritical: {
    backgroundColor: Colors.redBG

  },
  conflictIconMinor: {
    color: Colors.redFG
  },
  buttonShadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  }
});

export const THREAD_WIDTH = 2;

export { styles };
