import {
  ContainerType,
  FirestoreConstants,
  PathConstants,
  dbPath,
  elementId,
  ElementPath,
  PublicPath,
  PersonalPath,
} from './typings/types';
import { ContainerData } from './dataModel';
import make_uuid from 'react-uuid';
import { Platform } from 'react-native';
import { StackActionType, StackActions } from '@react-navigation/native';

export function containerTypeToString(
  type: ContainerType,
  localizedStrings: any,
) {
  let result = localizedStrings.none;
  Object.entries(ContainerType).forEach(([key, value]) => {
    if (value === type) {
      result = localizedStrings[key.toLowerCase()];
    }
  });
  return result;
}

export function getLocationFromPath(path: dbPath): PathConstants {
  return path.split('_')[0] as PathConstants;
}

export function getIdFromPath(path: dbPath): elementId {
  return path.split('_').pop() || '';
}

export function getContainerTypeFromId(id: elementId): ContainerType {
  return id.split('-')[0] as ContainerType;
}

export function getContainerTypeFromPath(path: dbPath): ContainerType {
  return getContainerTypeFromId(getIdFromPath(path));
}

export function createPathWithUUID(
  location: PathConstants,
  typeId: ContainerType,
): elementId {
  return location + '_' + typeId + '-' + make_uuid();
}

export function createPersonalPathFromId(id: elementId): dbPath {
  return PathConstants.PERSONAL + '_' + id;
}

export function createPublicPathFromId(id: elementId): elementId {
  return PathConstants.PUBLIC + '_' + id;
}

export function convertToPublicElementInfo(info: ElementPath): ElementPath {
  if (info.id !== FirestoreConstants.ROOT_ID) {
    info.loc = PathConstants.PUBLIC;
    return info;
  } else {
    throw new Error('Cannot convert root element info to public element info');
  }
}

export function convertPathToInfo(path: dbPath): ElementPath {
  return {
    loc: getLocationFromPath(path),
    id: getIdFromPath(path),
    type: getContainerTypeFromPath(path),
  } as PublicPath | PersonalPath;
}

export function convertInfoToPath(info: ElementPath): dbPath {
  if (info.id === FirestoreConstants.ROOT_ID) {
    return FirestoreConstants.ROOT_PATH;
  }
  const typeIncludedInId = info.id[1] === '-';
  if (typeIncludedInId) {
    return info.loc + '_' + info.id;
  } else {
    return info.loc + '_' + info.type + '-' + info.id;
  }
}

export function pathIsPublic(path: dbPath): boolean {
  return path.split('_')[0] === PathConstants.PUBLIC;
}

export function isEditingAllowed(container: ContainerData): boolean {
  return !(
    container.getPath() !== FirestoreConstants.ROOT_PATH &&
    (!container.isOwnedByCurrentUser() || container.isPublic())
  );
}

export function applyOnPlatform(web: any, native: any): any {
  if (Platform.OS === 'web') {
    return web;
  } else {
    return native;
  }
}

export function isEmpty(s: string): boolean {
  return s === undefined || s === null || s === '';
}

export function locToUrlString(loc: PathConstants): string {
  switch (loc) {
    case PathConstants.PERSONAL:
      return 'personal';
    case PathConstants.PUBLIC:
      return 'public';
    case PathConstants.GROUP:
      return 'group';
    default:
      throw new Error('Invalid location');
  }
}

export function typeToUrlString(type: ContainerType): string {
  switch (type) {
    case ContainerType.NONE:
      return 'none';
    case ContainerType.STATEMENT:
      return 'statement';
    case ContainerType.SOURCE:
      return 'source';
    case ContainerType.STATEMENT_GROUP:
      return 'argument';
    case ContainerType.CATEGORY:
      return 'topic';
    default:
      throw new Error('Invalid type');
  }
}

export function urlStringToLoc(urlString: string): PathConstants {
  switch (urlString) {
    case 'personal':
      return PathConstants.PERSONAL;
    case 'public':
      return PathConstants.PUBLIC;
    case 'group':
      return PathConstants.GROUP;
    default:
      if (Object.values(PathConstants).includes(urlString as PathConstants)) {
        return urlString as PathConstants;
      } else {
        throw new Error('Invalid location');
      }
  }
}

export function urlStringToType(urlString: string): ContainerType {
  switch (urlString) {
    case 'none':
      return ContainerType.NONE;
    case 'statement':
      return ContainerType.STATEMENT;
    case 'source':
      return ContainerType.SOURCE;
    case 'argument':
      return ContainerType.STATEMENT_GROUP;
    case 'topic':
      return ContainerType.CATEGORY;
    default:
      if (Object.values(ContainerType).includes(urlString as ContainerType)) {
        return urlString as ContainerType;
      } else {
        throw new Error('Invalid type');
      }
  }
}

export function convertInfoToRouteParams(info: ElementPath): any {
  return {
    loc: locToUrlString(info.loc),
    id: info.id,
    type: typeToUrlString(info.type),
  };
}

export function convertRouteParamsToInfo(params: any): ElementPath {
  return {
    loc: urlStringToLoc(params.loc),
    id: params.id,
    type: urlStringToType(params.type),
  } as ElementPath;
}

export function createStackActionFromInfo(info: ElementPath): StackActionType {
  let screen = '';
  switch (info.loc) {
    case PathConstants.PERSONAL:
      screen = 'PersonalStatementView';
      break;
    case PathConstants.PUBLIC:
      screen = 'PublicStatementView';
      break;
    case PathConstants.GROUP:
      screen = 'GroupStatementView';
      break;
    default:
      throw new Error('Invalid location');
  }
  return StackActions.push(screen, info);
}

export function selectScreenFromInfo(info: ElementPath): string {
  switch (info.loc) {
    case PathConstants.PERSONAL:
      return 'PersonalStatementView';
    case PathConstants.PUBLIC:
      return 'PublicStatementView';
    case PathConstants.GROUP:
      return 'GroupStatementView';
    default:
      throw new Error('Invalid location');
  }
}

export function containerToLink(c: ContainerData): string {
  return `https://app.lucid-mind.eu/${locToUrlString(c.getLocation())}/${typeToUrlString(c.getType())}/${c.getId()}`;
}