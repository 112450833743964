import { ActionSheet, ButtonProps, Colors, TouchableOpacity } from 'react-native-ui-lib';
import AddIcon from '../../assets/res/add.svg';
import { ContainerType, elementId, ElementPath } from '../typings/types';
import React, { useState } from 'react';
import { strings } from '../localization/localization.web';
import { containerTypeToString } from '../helperFunctions';
import { Pressable } from 'react-native';
import { useIsMobile } from '../MediaQueries';
import { useUserState } from '../firebaseWrapper/firebaseWrapper';
import { LogInForFeatureAlert } from './Alert';

type FABprops = {
  navigation: any;
  parentInfo: ElementPath;
  possibleTypes: ContainerType[];
};

export function FloatingActionButton(props: FABprops): JSX.Element {

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const [user, loading] = useUserState();

  const options: ButtonProps[] = props.possibleTypes.map((type: ContainerType) => {
    const option: ButtonProps = {
      label: containerTypeToString(type, strings),
      onPress: () => {
        user.isAnonymous ?
          LogInForFeatureAlert()
          :
          props.navigation.push('CreateDialog', {
            typeId: type,
            possibleTypes: props.possibleTypes,
            ...props.parentInfo,
          });
      }
    }
    return option;
  })

  return (
    <Pressable
      style={{
        borderRadius: 30,
        position: 'absolute',
        right: 0,
        bottom: isMobile ? 45 : 0,
        margin: 15,
        padding: 15,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.iconPrimary,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,

        elevation: 6,
      }}
      onPress={() => {
        setShowMenu(true)
      }}>
      <AddIcon fill={Colors.primaryBG} style={{ alignSelf: 'center' }} />
      <ActionSheet
        visible={showMenu}
        dialogStyle={{
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          backgroundColor: Colors.quaternaryBG,
        }}
        options={options}
        onDismiss={() => setShowMenu(false)}
      />
    </Pressable>
  );
}
