import { en } from '../localization/en';
import { de } from '../localization/de';
import LocalizedStrings from 'react-localization';
import {createContext} from 'react';

const languages = {en, de};

export const strings = new LocalizedStrings(languages);

export const LocalizedStringsContext = createContext(
  new LocalizedStrings(languages),
);