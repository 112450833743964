import React, { useContext, useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Button, Colors, Text, TextField, View } from 'react-native-ui-lib';

import { LocalizedStringsContext } from '../localization/localization.native';
import { styles } from '../styles';
import {
  createUser,
  sendPasswordResetEmail,
  signIn,
  signOut,
  useLoggedInState,
  useUserEmail, useUserName
} from '../firebaseWrapper/firebaseWrapper';
import { navigationProps } from '../typings/types';
import { Header } from './Header';
import LinearGradient from 'react-native-linear-gradient';
import { strings } from '../localization/localization.web';
import { ButtonPrimary } from './Buttons';
import { alert } from './Alert';
import { useIsMobile } from '../MediaQueries';
/**
 *
 * @param navigation Used to navigate between screens.
 * @returns Component for login.
 */
export function Login({ navigation }: navigationProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const isMobile = useIsMobile();

  const loginUser = () => {
    if (mail === '' || password === '') {
      alert(strings.enter_email_password);
      return;
    }

    signIn(mail, password)
      .then(() => {
        window.location.replace('https//:app.lucid-mind.eu/personal');
      })
      .catch(error => {
        if (error.code === 'auth/invalid-email') {
          alert(strings.invalid_email);
        } else if (
          error.code === 'auth/user-not-found' ||
          error.code === 'auth/wrong-password'
        ) {
          alert(strings.wrong_email_password);
        } else {
          alert(strings.error + ': ' + error);
        }
      })
      ;
  };

  return (
    <View flex bg-primaryBG>
      <View flex style={{
        backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
        borderRadius: isMobile ? 0 : 20,
        marginTop: isMobile ? 0 : 70,
        marginHorizontal: isMobile ? 0 : 5,
        marginBottom: isMobile ? 0 : 5,
      }}>
        <View flex centerV margin-10 style={{
          maxWidth: 1000,
          alignSelf: 'center',
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
        }}>
          <Text h2 marginV-10 marginH-5 textPrimary>
            {strings.login}
          </Text>
          <TextField
            style={styles.inputContainer}
            keyboardType="email-address"
            placeholder={strings.email}
            placeholderTextColor={Colors.textSecondary}
            onChangeText={newMail => setMail(newMail)}
            value={mail}
          />
          <TextField
            style={styles.inputContainer}
            secureTextEntry={true}
            placeholder={strings.password}
            placeholderTextColor={Colors.textSecondary}
            onChangeText={newPassword => setPassword(newPassword)}
          />
          <View row centerV marginB-10 marginH-10 style={{ justifyContent: 'flex-end', marginTop: -3 }}>
            <Text
              textSecondary
              onPress={() => {
                if (mail === '') {
                  alert(strings.enter_email);
                } else {
                  sendPasswordResetEmail(mail).then(() => {
                    alert(strings.reset_link_sent);
                  });
                }
              }}
              style={[{ textAlign: 'right' }]}>
              {strings.forgot_password}
            </Text>
          </View>
          <View marginT-10 marginB-0>
            <ButtonPrimary label={strings.login} onPress={() => loginUser()} />
          </View>
          <View row centerV marginV-5 marginH-5>
            <Text textPrimary>
              {strings.no_account + ' '}
            </Text>
            <Text textSecondary onPress={() => navigation.navigate('register')}>
              {strings.register_call_to_action + ''}
            </Text>
          </View>
        </View>
      </View>
    </View>

  );
}

/**
 *
 * @param navigation Used to navigate between screens.
 * @returns Component for registration.
 */
export function Registration({ navigation }: navigationProps): JSX.Element {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const strings = React.useContext(LocalizedStringsContext);
  const isMobile = useIsMobile();

  const registerUser = () => {
    if (mail === '' || password === '' || userName === '') {
      alert('Please enter your email, username and password.');
      return;
    }

    createUser(mail, password, userName)
      .then(() => window.location.replace('https//:app.lucid-mind.eu/personal'))
      .catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          alert('That email address is already in use!');
        } else if (error.code === 'auth/invalid-email') {
          alert('That email address is invalid!');
        } else if (error.code === 'auth/weak-password') {
          alert('The password is too weak!');
        } else {
          alert('Error:' + error);
        }
        console.error('Error:' + error);
      });
  };

  return (
    <View flex bg-primaryBG>
      <View flex style={{
        backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
        borderRadius: isMobile ? 0 : 20,
        marginTop: isMobile ? 0 : 70,
        marginHorizontal: isMobile ? 0 : 5,
        marginBottom: isMobile ? 0 : 5,
      }}>
        <View flex centerV margin-10 style={{
          maxWidth: 1000,
          alignSelf: 'center',
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
        }}>
          <Text h2 marginV-10 marginH-5 textPrimary>
            {strings.register}
          </Text>
          <TextField
            style={styles.inputContainer}
            placeholder={strings.display_name}
            onChangeText={newUserName => setUserName(newUserName)}
            placeholderTextColor={Colors.textSecondary}
          />
          <TextField
            style={styles.inputContainer}
            keyboardType="email-address"
            placeholder={strings.email}
            placeholderTextColor={Colors.textSecondary}
            onChangeText={newMail => setMail(newMail)}
            value={mail}
          />
          <TextField
            style={styles.inputContainer}
            secureTextEntry={true}
            placeholder={strings.password}
            placeholderTextColor={Colors.textSecondary}
            onChangeText={newPassword => setPassword(newPassword)}
          />
          <View marginT-20 marginB-0>
            <ButtonPrimary label={strings.register} onPress={() => registerUser()} />
          </View>
          <View row centerV marginV-5 marginH-5>
            <Text textPrimary>
              {strings.already_have_account + ' '}
            </Text>
            <Text textSecondary onPress={() => navigation.navigate('login')}>
              {strings.login + '.'}
            </Text>
          </View>
        </View>
      </View>
    </View >
  );
}

/**
 *
 * @returns Component for authentication status.
 */
function AuthenticationStatus(): JSX.Element {
  const isLoggedIn = useLoggedInState();

  if (!isLoggedIn) {
    return (
      <Text
        style={{ margin: 5, fontSize: 15, color: 'blue', textAlign: 'center' }}>
        Not logged in.
      </Text>
    );
  } else {
    return <AuthenticationStatusLoggedIn />;
  }
}

function AuthenticationStatusLoggedIn(): JSX.Element {
  const strings = React.useContext(LocalizedStringsContext);
  const userName = useUserName();
  const userEmail = useUserEmail();

  return (
    <View margin-10>
      <Text style={styles.blueInfoText}>
        Logged in as {userName}{' '}
        with {userEmail}.
      </Text>
      <Button style={styles.greenButton} onPress={signOut}>
        <Text style={styles.greenButtonText}>{strings.logout}</Text>
      </Button>
    </View>
  );
}

/**
 *
 * @returns Component for authentication flow.
 */
function AuthenticationFlow(): JSX.Element {
  const Tab = createBottomTabNavigator();
  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG,
      }}>
      <Tab.Navigator
        initialRouteName="register"
        tabBar={() => undefined}
        screenOptions={Header()}>
        <Tab.Screen name="register" component={Registration} />
        <Tab.Screen name="login" component={Login} />
      </Tab.Navigator>
    </View>
  );
}

export { AuthenticationFlow, AuthenticationStatus };

// /** // TODO Delete as replaced by ButtonPrimary
//  *
//  * @returns Primary button component.
//  */
// export function PrimaryButton({onPress, text}: {onPress: Function, text: string}): JSX.Element {
//   return (
//     <>
//     <LinearGradient
//       colors={[Colors.backgroundAccent, Colors.backgroundAccent2]}
//       style={[{borderRadius: 20}, styles.buttonShadow]}
//     >
//       <Button
//         onPress={() => onPress()}
//         style={{backgroundColor: 'transparent'}}
//       >
//         <Text color={Colors.textAccentSecondary}>
//           {text}
//         </Text>
//       </Button>
//     </LinearGradient>
//     </>
//   );
// }