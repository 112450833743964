import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext, useState } from 'react';
import { SkeletonView, Text, TouchableOpacity } from 'react-native-ui-lib';
import { useUserName } from '../firebaseWrapper/firebaseWrapper';
import { LocalizedStringsContext } from '../localization/localization.native';

type userNameProps = {
  userId: string;
  navigation?: any;
  anonymizeUser?: boolean;
};

export function UserName({ userId, navigation, anonymizeUser = false }: userNameProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const [userName, loading] = useUserName(userId);
  const [showUserName, setShowUserName] = useState(!anonymizeUser);

  // TODO: Remove this workaround and either use useNavigation() or require passing of navigation as prop.
  // const navigation = props.navigation;

  if (loading) {
    return <SkeletonView width={50} height={14} borderRadius={2} />;
  } else {
    return (
      <TouchableOpacity
        onPress={() => {
          if (userName === strings.unknown_user || navigation === undefined) {
            return;
          } else if (!showUserName) {
            setShowUserName(true);
          } else {
            navigation.navigate('profile', { userId: userId });
          }
        }}>
        <Text textSecondary small bold>
          {showUserName ? userName : strings.anonymous}
        </Text>
      </TouchableOpacity>
    );
  }
}

dayjs.extend(relativeTime);

export function Timestamp(props: {
  timestamp: Date;
}): JSX.Element {
  return (
    <Text small textSecondary>
      {props.timestamp ? ' ' + dayjs(props.timestamp).fromNow() : ''}
    </Text>
  );
}
